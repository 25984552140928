exports.genderList=[
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
]
exports.maritalStatusList=[
    { value: "married", label: "Married" },
    { value: "unmarried", label: "UnMarried" },
    { value: "unknown", label: "Unknown" },
    { value: "widow", label: "Widow" },
    { value: "others", label: "Others" },
]
exports.occupationList=[
    { value: "employee", label: "Employee" },
    { value: "govt", label: "Govrnment Employee" },
    { value: "private", label: "Private Employee" },
    { value: "self_employed", label: "Self-employed" },
    { value: "non_employee", label: "Non-employee" },
    { value: "business", label: "Business " },
    { value: "freelancer", label: "Freelancer" },
    { value: "contractor", label: "Contractor" },
    { value: "intern", label: "Intern" },
    { value: "Former", label: "Former" },
    { value: "others", label: "Others" },
]