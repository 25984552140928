import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { getCall, LoggedpostCall } from "../../services/APICall";
import ToastComponent from "../../UI/Toast/Toast";
import DashboardCard from "../../UI/Card/DashboardCard/DashboardCard";
import ProfileImg from "../../assets/img/user-dashboard/profile.png";
import DashboardIcon1 from "../../assets/img/user-dashboard/dashboard-icon-1.svg";
import DashboardIcon2 from "../../assets/img/user-dashboard/dashboard-icon-2.svg";
import DashboardIcon3 from "../../assets/img/user-dashboard/dashboard-icon-3.svg";
import DashboardIcon4 from "../../assets/img/user-dashboard/dashboard-icon-4.svg";
import DashboardIcon5 from "../../assets/img/user-dashboard/dashboard-icon-5.svg";
import DashboardIcon6 from "../../assets/img/user-dashboard/dashboard-icon-6.svg";
import "./Dashboard.css";
import { getLocalStorage } from "../../utils/auth";
import { reset, toggleInvestView, toggleSipCalculateView } from "../../slicer/investSlicer";
import { reset as sipRest } from "../../slicer/sipSlicer";
import Input from "../../UI/Input/Input";
import { useForm } from "react-hook-form";
import { optimizeList } from "../../utils/optimize";

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const selector = useSelector((state) => state.sip);
  const investModalViewData = useSelector((state) => state.invest);
  const dispatch = useDispatch();
  const [investData, setInvestData] = useState(selector.initialData);
  const [visible, setVisible] = useState(false);
  const [dashData, setDashData] = useState();
  const [userData, setUserData] = useState();
  const [profileData,setProfileData]=useState();
  const [relationList,setRelationList] = useState();
  const [toastContent, setToastContent] = useState({
    type: "",
    show: false,
    message: "",
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (location?.state) {
      setVisible(true);
    }
  }, [location]);
  useEffect(()=>{
    setInvestData(selector.initialData)
  },[selector])
  useEffect(()=>{
    setVisible(investModalViewData.investModal)

  },[investModalViewData])
  useEffect(() => {
    setUserData(getLocalStorage());
    dashboardData();getRelationList();
    getUserData();
  }, []);

  const dashboardData = async () => {
    try {
      const { response, result } = await getCall("user-dashboard");
      setDashData(result.data);
    } catch (error) {
      console.error("API Error:", error);
      setToastContent({
        type: "error",
        show: true,
        message: "Failed to create investment",
      });
    }
  };
  const getUserData =async ()=>{
    const { response, result } = await getCall("user-profile");
    setProfileData(result.data)

  }

  const getRelationList =async()=>{
    const {response,result} = await getCall("nominee-dropdown");
    
    setRelationList(optimizeList(result.data,"name","name"));
    console.log(result)

  }
  const handleToastClose = () => {
    setToastContent({ ...toastContent, show: false });
  };

  const investHandler = async (data) => {
    const payload = {
      invest_for: "plan for dream house",
      invest_type: investData.type,
      pay_amount: investData.amount,
      return_rate: investData.rate,
      time_period: investData.time,
      ...data
    };
    try {
      const { response, result } = await LoggedpostCall(
        "investment-create",
        payload
      );
      dispatch(reset())
      dispatch(sipRest());
      if (response.ok) {
        navigate("/user/investment");
      }
      setToastContent({
        type: result?.status || "error",
        show: true,
        message: result?.message || "Something went wrong",
      });
    } catch (error) {
      console.error("API Error:", error);
      setToastContent({
        type: "error",
        show: true,
        message: "Failed to create investment",
      });
    } finally {
      setVisible(false);
    }
  };

  // const investSub=(data)=>{
  //   console.log(data)
  // }

  return (
    <>
      <ToastComponent
        className={toastContent.type === "error" ? "error-toast" : ""}
        type={toastContent.type}
        show={toastContent.show}
        message={toastContent.message}
        onClose={handleToastClose}
      />
      <div className="dashboard py-3">
        <div className="container">
          <div className="user-info-head d-flex justify-content-start gap-2">
            {profileData?.image&&<div className="user-info-head__img-container">
            <img src={profileData?.image} alt="profile" style={{width:"70px"}}/>
            </div>}
            <div className="user-info-head__content-container ">

              <div className="head">Hi, {userData?.name}!</div>
              <div className="content">Welcome back.</div>
            </div>
           
          </div>

          <div className="user-info__card-container d-flex flex-wrap  justify-content-center justify-content-lg-start">
            <DashboardCard
              icon={DashboardIcon1}
              title="No. of Investment"
              value={dashData?.no_investment || "0.00"}
            />
            <DashboardCard
              icon={DashboardIcon2}
              title="Invested Amount"
              value={dashData?.invested_amount || "0.00"}
            />
            <DashboardCard
              icon={DashboardIcon3}
              title="Pending Due Amount"
              value={dashData?.pending_due || "0.00"}
            />
            <DashboardCard
              icon={DashboardIcon4}
              title="Current Investment Returns"
              value={dashData?.invested_amount || "0.00"}
            />

            <DashboardCard
              icon={DashboardIcon5}
              title="Next Instalment"
              value={dashData?.next_month_amount || "0.00"}
            />
          </div>
        </div>
      </div>

      <div className="invest-dialog">
          <Dialog
          draggable={false}resizable={false}
          dismissableMask={true}
          closeOnEscape={true}
            header="Investment Details"
            visible={visible}
            style={{ width: "310px" }}
            className="invest-dialog"
            onHide={() => {dispatch(toggleInvestView(false));setVisible(false)}}
          >
            <div className="p-3">
              <div className="text-end">
                <button className="btn red-head fw-bold" onClick={()=>{
                  dispatch(toggleInvestView(false));
                  setVisible(false);
                  dispatch(toggleSipCalculateView(true))
                  }}>Change Plan</button>
              </div>
              <div className="block d-flex">
                <span className="label">Investment Type </span>
                <span>:</span>
                <span className="ans">{investData?.type}</span>
              </div>
              <div className="block d-flex">
                <span className="label">Amount </span>
                <span>:</span>
                <span className="ans">{investData?.amount}</span>
              </div>
              <div className="block d-flex">
                <span className="label">Interest </span>
                <span>:</span>
                <span className="ans">{investData?.rate}</span>
              </div>
              <div className="block d-flex">
                <span className="label">Time </span>
                <span>:</span>
                <span className="ans">{investData?.time}</span>
              </div>
              <form onSubmit={handleSubmit(investHandler)}>
                <p className="fw-bold mt-3">Nominee Details</p>
                <Input 
                id="nominee_name"
                label="Name"
                type="text"
                placeholder="Nominee Name"
                register={register}
                validation={{ required: "Provide Valid Nominee Name" }}
                errors={errors}
                />
                <Input 
                id="nominee_age"
                label="Age"
                type="number"
                placeholder="Nominee Age"
                register={register}
                validation={{ required: "Provide Valid Nominee Name" }}
                errors={errors}
                min="1"
                max="100"
                />
                <Input 
                id="nominee_relationship"
                label="Relationship"
                type = "select"
                placeholder="Select Relationship"
                register={register}
                validation={{ required: "Provide Valid Relationship" }}
                errors={errors}
                options={relationList}
                />
              <div className="my-2 mt-4 text-center">
                <button className="btn red__btn" >
                  Invest Now
                </button>
              </div>
              </form>
              


              {/* <div className="my-2 mt-4 text-center">
                <button className="btn red__btn" onClick={investHandler}>
                  Invest Now
                </button>
              </div> */}
            </div>
          </Dialog>

      </div>
    </>
  );
}
