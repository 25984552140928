import { NavLink } from "react-router-dom";
import "./About.css";
import LogoCarousel from "../../UI/Carousel/LogoCarousel/LogoCarousel";
import { TabView, TabPanel } from "primereact/tabview";
import ImageSlider from "../../UI/Carousel/LogoCarousel/LogoSlider";

export default function About() {
  return (
    <>
      {/* <!-- head-container --> */}
      <div className="top-head-container">
        <div className="container top-head-sub-container">
          <h2 className="head text--5xl">About Us</h2>
        </div>
        <div className="page-info">
          <NavLink to="/">Home</NavLink> / About Us
        </div>
      </div>
      <div className="values-container py-1 py-md-4">
        <div className="container">
          <div className="row py-md-4 pt-3 align-items-center">
            <div className="col-md-6 our-values_container">
              <h6 className="red-head text--2xl">Our Values</h6>
              <p className=" fw-bold text--6xl fst-italic">
                A consistent and rigorous approach
              </p>
            </div>
            <div className="col-md-6">
              {/* <div className="tab-container">
                <nav>
                  <div
                    className="nav nav-tabs justify-content-between"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Investment
                    </button>
                    <button
                      className="nav-link"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Knowledge
                    </button>
                    <button
                      className="nav-link"
                      id="nav-contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      Growth
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    aaA Falcon Asset Management Unit is a firm which ensures the
                    asset Management of an individual investors or an
                    institution or both. Our Management Unit manages the
                    portfolio of an individual ensuring them to enlighten in
                    different phase of life through our SIP plan. Our Management
                    Unit manages the portfolio of an individual ensuring them to
                    enlighten in different phase of life through our SIP plan.
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    .2.. A Falcon Asset Management Unit is a firm which ensures
                    the asset Management of an individual investors or an
                    institution or both. Our Management Unit manages the
                    portfolio of an individual ensuring them to enlighten in
                    different phase of life through our SIP plan. Our Management
                    Unit manages the portfolio of an individual ensuring them to
                    enlighten in different phase of life through our SIP plan.
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    ..3. A Falcon Asset Management Unit is a firm which ensures
                    the asset Management of an individual investors or an
                    institution or both. Our Management Unit manages the
                    portfolio of an individual ensuring them to enlighten in
                    different phase of life through our SIP plan. Our Management
                    Unit manages the portfolio of an individual ensuring them to
                    enlighten in different phase of life through our SIP plan.
                  </div>
                </div>
              </div> */}
              <div className="tab-container">
                <TabView>
                  <TabPanel header="Investment">
                    <p className="m-0">
                    A Falcon Asset Management Unit is a firm which ensures the asset Management of an individual investors or an institution or both. Our Management Unit manages the portfolio of an individual ensuring them to enlighten in different phase of life through our SIP plan. <br /> <br/> Our Management Unit manages the portfolio of an individual ensuring them to enlighten in different phase of life through our SIP plan.
                    </p>
                  </TabPanel>
                  <TabPanel header="Knowledge">
                    <p className="m-0">
                    A Systematic Investment Plan (SIP) allows investors to invest a fixed amount regularly in mutual funds, fostering disciplined investment habits. By averaging out the cost over time, SIPs help mitigate market volatility and reduce the risks associated with market timing. The power of compounding plays a key role in SIP growth, as earnings are reinvested, leading to exponential growth over the long term. Consistency is crucial; sticking to the plan during market ups and downs ensures steady wealth accumulation. SIPs are ideal for long-term financial planning with lower risk exposure.                 
                    </p>
                                       </TabPanel>
                  <TabPanel header="Growth">
                    <p className="m-0">
                    A consistent and rigorous approach to SIP (Systematic Investment Plan) investing can lead to significant long-term growth. By investing regularly, you benefit from rupee cost averaging, reducing the impact of market volatility. The power of compounding enhances returns over time, making disciplined investing crucial. Staying committed to your SIP, regardless of market conditions, ensures steady wealth accumulation. This strategy is ideal for those seeking long-term financial goals with minimized risk.                    </p>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- OUR STORY CONTAINER --> */}
      <div className="our-story-container my-4">
        <div className="container py-md-5 pt-4 py-3">
          <div className="our-story text--2xl">Our Story</div>

          <p className="text--6xl mt-3 fw-bold">Leadership at The Falcon</p>
          <p>
            A Falcon Asset Management Unit is a firm which ensures the asset
            Management of an individual investors or an institution or both. Our
            Management Unit manages the portfolio of an individual ensuring them
            to enlighten in different phase of life through our SIP plan. Our
            Management Unit manages the portfolio of an individual ensuring them
            to enlighten in different phase of life through our SIP plan.
          </p>
          <p>
            A Falcon Asset Management Unit is a firm which ensures the asset
            Management of an individual investors or an institution or both. Our
            Management Unit manages the portfolio of an individual ensuring them
            to enlighten in different phase of life through our SIP plan. Our
            Management Unit manages the portfolio of an individual ensuring them
            to enlighten in different phase of life through our SIP plan. A
            Falcon Asset Management Unit is a firm which ensures the asset
            Management of an individual investors or an institution or both. Our
            Management Unit manages the portfolio of an individual ensuring them
            to enlighten in different phase of life through our SIP plan. Our
            Management Unit manages the portfolio of an individual ensuring them
            to enlighten in different phase of life through our SIP plan.
          </p>
        </div>
      </div>

      {/* <!-- INDUSTRIAL AREA --> */}
      <div className="industrial-area-container py-md-5 pb-1 text-center">
        <div className="container">
          <p className="red-head fw-bold text--2xl">Industrial Area</p>
          <p className="blue-head text--4xl fw-bold">
            Different SIP for Different Financial Goals
          </p>
          <div className="logo-carousel mx-auto">
            <LogoCarousel />
          </div>
        </div>
      </div>
    </>
  );
}
