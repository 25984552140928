import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import "react-multi-carousel/lib/styles.css";
import './assets/styles/hover-min.css';
import 'react-phone-number-input/style.css';
import { Provider } from 'react-redux';

/* PRIME NG */
import 'primereact/resources/themes/saga-blue/theme.css';  // Or any other theme
import 'primereact/resources/primereact.min.css';
import { store } from './store/store';

/* SLICK SLIDER */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Provider>
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
