import "./SIP.css";

import SIPImg from "../../assets/img/sip/sip-image.svg";
import SIPIcon1 from "../../assets/img/sip/sip-icon-1.svg";
import SIPIcon2 from "../../assets/img/sip/sip-icon-2.svg";
import SIPIcon3 from "../../assets/img/sip/sip-icon-3.svg";
import SIPIcon4 from "../../assets/img/sip/sip-icon-4.svg";
import SIPIcon5 from "../../assets/img/sip/sip-icon-5.svg";
import SIPIcon6 from "../../assets/img/sip/sip-icon-6.svg";
import FormBtn from "../../UI/Button/FormBtn/FormBtn";
import { useNavigate } from "react-router-dom";
export default function SIP() {
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- head-container --> */}
      <div className="top-head-container">
        <div className="container top-head-sub-container">
          <h2 className="head">What is SIP?</h2>
        </div>
        <div className="page-info">
          <a href="./index.html">Home</a> / What is SIP?
        </div>
      </div>
      {/* <!--START SECOND CONTAINER --> */}
      <div className="second-container red-light-bg py-5 pb-md-5 pb-1">
        <div className="container">
          <div className="row justify-content-md-between align-items-center">
            <div className="col-md-6">
              <h6 className=" blue-head second-container-head text--6xl mb-4">
                What is Systematic Investment Plan (
                <span className="red-head">SIP</span>)?
              </h6>
              <p className="text__light">
                SIP is an investment route offered by Mutual Funds wherein one
                can invest a fixed amount in a Mutual Fund scheme at regular
                intervals– say once a month or once a quarter, instead of making
                a lump-sum investment. The installment amount could be as little
                as INR 5000 a month and is similar to a recurring deposit. It’s
                convenient as you can give your bank standing instructions to
                debit the amount every month. SIP has been gaining popularity
                among Indian MF investors, as it helps in investing in a
                disciplined manner without worrying about market volatility and
                timing the market.
              </p>

              <p className="text__light">
                SIP has been gaining popularity among Indian MF investors, as it
                helps in investing in a disciplined manner without worrying
                about market volatility and timing the market.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img src={SIPImg} alt="SIP level" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END SECOND CONTAINER --> */}
      {/* <!-- START TIME LINE CONTAINER --> */}
      <section className="timeline__container py-5 pb-md-5 pb-0">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between flex-column-reverse flex-lg-row">
            <div className="col-lg-7">
              <ul className="timeline">
                <li className="timeline-inverted-pop">
                  <div className="timeline-badge secondary hvr-pop">
                    <span className="dot"></span>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4 className="timeline-title left">1. Initiation</h4>
                    </div>
                    <div className="timeline-body">
                      <p>
                        SIP begins the process by sending an INVITE request to
                        establish a call
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted timeline-inverted-pop">
                  <div className="timeline-badge primary ">
                    {/* <!-- <i className="glyphicon glyphicon-credit-card"></i> --> */}
                    <span className="dot"></span>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4 className="timeline-title right">2. Negotiation</h4>
                    </div>
                    <div className="timeline-body">
                      <p>
                        SIP begins the process by sending an INVITE request to
                        establish a call
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted-pop">
                  <div className="timeline-badge secondary hvr-pop">
                    <span className="dot"></span>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4 className="timeline-title left">3. Establishment</h4>
                    </div>
                    <div className="timeline-body">
                      <p>
                        SIP begins the process by sending an INVITE request to
                        establish a call
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted timeline-inverted-pop">
                  <div className="timeline-badge primary ">
                    {/* <!-- <i className="glyphicon glyphicon-credit-card"></i> --> */}
                    <span className="dot"></span>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4 className="timeline-title right">4. Communication</h4>
                    </div>
                    <div className="timeline-body">
                      <p>
                        SIP begins the process by sending an INVITE request to
                        establish a call
                      </p>
                    </div>
                  </div>
                </li>  
                <li className="timeline-inverted-pop">
                  <div className="timeline-badge secondary hvr-pop">
                    <span className="dot"></span>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4 className="timeline-title left">5. Termination</h4>
                    </div>
                    <div className="timeline-body">
                      <p>
                        SIP begins the process by sending an INVITE request to
                        establish a call
                      </p>
                    </div>
                  </div>
                </li>              
                
              </ul>
            </div>
            <div className="col-lg-5 text-center timeline__head">
              <h6 className="blue-head text--5xl px-3 fw-bold text-start mb-3">
                Understanding How <span className="red-head">SIP Protocol</span>{" "}
                Works
              </h6>
              <FormBtn
                onClick={() => navigate("/plan")}
                className="btn text-center fw-bold calculator-btn shutter_btn hvr-shutter-out-vertical"
              >
                Launch SIP Calculator
              </FormBtn>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END TIME LINE CONTAINER --> */}

      {/* <!-- START ADVANTAGES CONTAINER --> */}
      <section className="advantage__container mx-auto mb-5">
        <div className="container">
          <h6 className="text--4xl mb-5 pb-2 text-center blue-head">
            Advantages of <span className="red-head">SIP</span>{" "}
          </h6>
          <div className="d-flex advantage__container__flex flex-wrap justify-content-center justify-content-md-around mt-5">
            <div className="advantage__card card border-0">
              <div className="red-light-bg rounded advantage-sub__card">
                <img
                  src={SIPIcon1}
                  alt="Professionally Managed"
                  className="img-fluid"
                />
                <div>
                  <p className="blue-head advantage__card__head fw-bold">
                    Professionally Managed
                  </p>
                  <p>
                    One of the key benefits of investing in these funds is that
                    your money managed by professional money managers who have
                    years of investing experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="advantage__card card border-0">
              <div className="red-light-bg rounded advantage-sub__card">
                <img src={SIPIcon2} alt="Liquidity" className="img-fluid" />
                <div>
                  <p className="blue-head advantage__card__head fw-bold">
                    Liquidity
                  </p>
                  <p>
                    One of the key benefits of investing in these funds is that
                    your money managed by professional money managers who have
                    years of investing experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="advantage__card card border-0">
              <div className="red-light-bg rounded advantage-sub__card">
                <img src={SIPIcon3} alt="Returns" className="img-fluid" />
                <div>
                  <p className="blue-head advantage__card__head fw-bold">
                    Returns
                  </p>
                  <p>
                    One of the key benefits of investing in these funds is that
                    your money managed by professional money managers who have
                    years of investing experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="advantage__card card border-0">
              <div className="red-light-bg rounded advantage-sub__card">
                <img src={SIPIcon4} alt="Returns" className="img-fluid" />
                <div>
                  <p className="blue-head advantage__card__head fw-bold">
                    Affordability
                  </p>
                  <p>
                    One of the key benefits of investing in these funds is that
                    your money managed by professional money managers who have
                    years of investing experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="advantage__card card border-0">
              <div className="red-light-bg rounded advantage-sub__card">
                <img
                  src={SIPIcon5}
                  alt="Diversification"
                  className="img-fluid"
                />
                <div>
                  <p className="blue-head advantage__card__head fw-bold">
                    Diversification
                  </p>
                  <p>
                    One of the key benefits of investing in these funds is that
                    your money managed by professional money managers who have
                    years of investing experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="advantage__card card border-0">
              <div className="red-light-bg rounded advantage-sub__card">
                <img
                  src={SIPIcon6}
                  alt="Well Requlated"
                  className="img-fluid"
                />
                <div>
                  <p className="blue-head advantage__card__head fw-bold">
                    Well Requlated
                  </p>
                  <p>
                    One of the key benefits of investing in these funds is that
                    your money managed by professional money managers who have
                    years of investing experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END ADVANTAGES CONTAINER --> */}
    </>
  );
}
