export default function Privacy() {
  return (
    <>
      <div className="red-light-bg p-3 px-5">
        <h3 className="text-center p-3 fs-2 blue-head">Privacy Policy</h3>
        <p>
          {" "}
          &nbsp; &nbsp; &nbsp; This Privacy Policy constitutes an electronic
          record in accordance with the provisions of the Information Technology
          Act, 2000, Consumer Protection (E-Commerce) Rules, 2020 and Rule 3 (1)
          of the Information Technology (Intermediary Guidelines and Digital
          Media Ethics Code) Rules, 2021 (“Intermediaries Rules”) thereunder, as
          amended from time to time. In this Policy, references to “you”, “User”
          shall mean the end user accessing (http://falconventures.co.in/)
          “Website”, its contents and using the Services offered through the
          Falcon Platform [“Services”]. “We”, “us”, “our” shall mean The FALCON
          UNIT, its predecessors and successors in interest. We value the trust
          you place in us that's why we insist upon the highest standards for
          secure communication and customer information privacy. Please read the
          following statement to learn about our information gathering and
          dissemination practices. The Privacy Policy specified herein is
          subject to change at any time without notice. To make sure you are
          aware of any changes, please review this policy periodically. The
          Company collects several types of information which is primarily used
          to serve you better and maintain authenticity of Services by
          identifying you. Details of such information (collectively called
          “Information”) are as follows:
        </p>

        <ul className="document-list">
          <li>
            <p>
              We may collect, store and use the following kinds of Person and
              Non - Personal data:
            </p>
          </li>
          <li>
            <p>
              Automatically Collected Information: Information about your
              computer and about your visits to and use of the Platform, such as
              your IP address, user actions, e-mail address, mailing address,
              educational qualification details, phone number, geographical
              location, browser type, referral source, length of visit and
              number of page views or any other information that is identified
              with you personally. Additionally permanent and/or temporary
              cookies may be placed on your computer. Cookies are small files
              transferred to the hard drive of Your device that enable us to
              recognize Your browser and capture and remember certain
              information.
            </p>
            <p>
              They are also used to help us understand Your preferences based on
              previous or current activity on the Website, which allows us to
              improve our website. We also use cookies to help us compile
              aggregated and anonymized data about the traffic on the Website so
              that we can offer better experiences and tools in the future. We
              may also use trusted third-party services that track this
              information on our behalf. In order to delete such cookies, please
              follow the respective browser’s policies to remove locally stored
              cookies.
            </p>
          </li>
          <li>
            <p>
              Information You Give Us: Information that you provide to us for
              the purpose of registering with us or creating an account with us.
            </p>
          </li>
          <li>
            <p>
              Transactional Information: Information relating to any
              transactions carried out between you and us on or in relation to
              the Platform, including information relating to any purchases you
              make of our Courses/Content.
            </p>
          </li>
          <li>
            <p>
              Information Required for Services: Information that you provide to
              us for the purpose of subscribing to our Platform services, email
              notifications and/or newsletters.
            </p>
          </li>
          <li>
            <p>
              Any other information that you choose to send to us How do we use
              your personal data? Personal data submitted on our Platform will
              be used for the purposes specified in this privacy policy or in
              relevant parts of the Website.
            </p>
          </li>
          <li>
            <p>
              Provide, troubleshoot, and improve BI Services. We use your
              personal information to provide functionality, analyze
              performance, fix errors, and improve the usability and
              effectiveness of our Services.
            </p>
          </li>
          <li>
            <p>
              Recommendations and personalization. We use your personal
              information to recommend features, products, and services that
              might be of interest to you, identify your preferences, and
              personalize your experience with our Services.
            </p>
          </li>
          <li>
            <p>
              Communicate with you. We use your personal information to
              communicate with you in relation to Services via different
              channels (e.g., by phone, email, chat).
            </p>
          </li>
          <li>
            <p>
              Advertising. We use your personal information to display
              interest-based ads for features, products, and services that might
              be of interest to you. We do not use information that personally
              identifies you to display interest-based ads.
            </p>
          </li>
          <li>
            <p>
            We will at all times provide you with the option to not provide the
            Personal Information or Sensitive Personal Information (within the
            meaning of the IT Act, 2000 and its rules) that we seek from you.
            Further, you shall, at any time while using the Website/Service,
            also have an option to withdraw your consent given earlier to us to
            use such Personal Information or Sensitive Personal Information.
            Such withdrawal of consent is required to be sent in writing to us
            at THE FALCON UNIT. In such event however, the Company fully
            reserves the right not to allow further usage of the Website or
            provide any Services/Products thereunder to you.

            </p>
          </li>
        </ul>
      </div>
    </>
  );
}
