export default function DashboardCard({icon,title,value}){
    return<>
      <div className="info-card ">
              <div className="info-card__left">
                <img src={icon} alt="Current Investment Returns" />
              </div>
              <div className="info-card__right">
                <div className="head">{title}</div>
                <div className="ans">{value}</div>
              </div>
            </div>
    </>

}