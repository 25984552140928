import call from "../../assets/img/fot-icon-1.svg";
import mail from "../../assets/img/fot-icon-2.svg";
import facebookicon from "../../assets/img/facebook-icon.svg";
import instagramicon from "../../assets/img/instagram-icon.svg";
import twittericon from "../../assets/img/x-icon.svg";
import linkedinicon from "../../assets/img/pintrest-icon.svg";
export default function Topbar() {
  return (
    <>
      <div className="top-head ">
        <div className="text-dark d-flex justify-content-between w-100 align-items-center px-3 py-2">
          <div className="d-flex gap-3 ">
            <div className="contact-cont wobble-horizontal-on-hover">
              <img
                src={call}
                alt="call "
                className=""
              />
              <a href="tel:+919632499099">(+91) 96324 99099</a>
            </div>
            <div className="contact-cont">
              <img src={mail} alt="mail " />
              <a href="mailto:thefalcon-sip@gmail.com">
                thefalcon-sip@gmail.com
              </a>
            </div>
          </div>
          <div className=" ms-auto d-flex gap-2 text-end social-media-icons">
            {/* <a href="#">
              <img
                src={facebookicon}
                alt="Facebook "
                className="hvr-push"
              />
            </a>
            <a href="#">
              <img
                src={instagramicon}
                alt="Instagram "
                className="hvr-push"
              />
            </a>
            <a href="#">
              <img
                src={twittericon}
                alt="Twitter "
                className="hvr-push"
              />
            </a>
            <a href="#">
              <img
                src={linkedinicon}
                alt="Linkedin "
                className="hvr-push"
              />
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
}
