import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";

import { Paginator } from "primereact/paginator";

import { getCall } from "../../services/APICall";
import "./PaymentHistory.css";

import searchIcon from "../../assets/img/user-dashboard/shield-search.svg";
import UserTable from "../../UI/Table/UserTable/UserTable";
import SuccessIcon from "../../assets/img/user-dashboard/success.png";
import { MultiSelect } from 'primereact/multiselect';

export default function PaymentHistory() {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState(5);  // Number of rows per page
  const [first, setFirst] = useState(1); // Index of the first row to display
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // New state to track search input
  const [totalPage, setTotalPage] = useState(1);
  
  useEffect(() => {
    getPaymentHistory();
  }, []);

  const getPaymentHistory = async (page = 1) => {
    setLoading(true);
    const { response, result } = await getCall(`payment-list?page=${page}&rows=${rows}`);
    setLoading(false);

    if (response?.status === 200) {
      setPaymentList(result?.data?.data || []);
      setFilteredList(result?.data?.data);
      setRows(result?.data?.per_page || 5);  // Set number of rows per page
      setCount(result?.data?.total || 0);  // Set total count of records
      setTotalPage(Math.ceil(result?.total / result?.per_page)); // Calculate total pages
    } else {
      setPaymentList([]);
      setCount(0);
    }
  };

  const renderStatusBtn = ({ status }) => (
    <Button
      label={status === 1 ? "Successful" : "Failed"}
      className={`p-1 px-2 rounded padge-btn  ${
        status === 1 ? "padge-success" : "p-button-secondary"
      }`}
    />
  );

  const tableData = [
    { field: "investment_code", header: "Investment ID" },
    { field: "payment_date", header: "Date", className: "text-nowrap" },
    { field: "transaction_id", header: "Transaction ID" },
    { field: "order_id", header: "No. of Payment" },
    { field: "amount", header: "Transaction Amount" },
    { field: renderStatusBtn, header: "Status" },
  ];

  const onPageChange = (event) => {
    const newFirst = event.first;
    const newPage = newFirst / rows + 1;  // Calculate current page
    setFirst(newFirst);  // Update the first row index
    getPaymentHistory(newPage);  // Fetch the new page of data
  };

  const statusList = [
    { name: 'Successful', code: '1' },
    { name: 'Failed', code: '0' },
    
  ];

  // Filter investment list based on selected status and search query
  useEffect(() => {
    let filtered = paymentList;

    // Filter by status if any status is selected
    if (selectedStatus.length > 0) {
      filtered = filtered.filter((investment) =>
        selectedStatus.some((status) => Number(status.code) === Number(investment.is_active))
      );
    }

    // Filter by search query (invest code)
    if (searchQuery) {
      filtered = filtered.filter((investment) =>
        investment.investment_code.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredList(filtered);
  }, [selectedStatus, searchQuery, paymentList]);

  return (
    <>
      <div className="user-info-head  d-flex flex-column flex-lg-row justify-content-lg-between">
        <div className="user-info-head__content-container text-start w-100">
          <div className="head">Payment History</div>
          <div className="content">Manage your investment & renewals</div>
        </div>
        <div className="input-container w-100 order-3 order-lg-2 ms-auto me-lg-5 my-3 d-flex justify-content-between justify-content-lg-end gap-2">
          <div className="search-container form-control">
            <img src={searchIcon} alt="search" />
            <input
              type="text"
              placeholder="Search " // Updated placeholder text
              className="search"
              name="search"
              id="search"
              value={searchQuery} // Bind input to searchQuery state
              onChange={(e) => setSearchQuery(e.target.value)} // Update search query state on input
            />
          </div>

          <div className="dropdown status-select form-control">
            <MultiSelect 
              aria-labelledby="dd1"
              value={selectedStatus} 
              onChange={(e) => setSelectedStatus(e.value)} 
              options={statusList} 
              optionLabel="name"
              placeholder="Select Status"
              maxSelectedLabels={5} 
              className="w-full md:w-20rem" 
              style={{ width: "150px" }} 
            />
          </div>
        </div>
      </div>
      <div className="payment-list-contianer">
        <div className="dashboard-view">
          <UserTable
            loading={loading}
            totalRecords={count}
            rows={rows}
            first={first}  // Pass first row index to Paginator
            columns={tableData}
            data={filteredList}
            onPageChange={onPageChange}
          />
        </div>
        <div className="mobile-view">
          <div className="row accordion-list-head w-100 mx-auto">
            <div className="col-6">Investment ID</div>
            <div className="col-2">Date</div>
            <div className="col-2">Transaction Amount</div>
            <div className="col-2">Status</div>
          </div>
          <Accordion activeIndex={0}>
            {filteredList.map((list) => (
              <AccordionTab
                header={
                  <span className="row align-items-center ">
                    <span className="col-6">{list?.investment_code}</span>
                    <span className="col-2 text-nowrap">
                      {list?.payment_date}
                    </span>
                    <span className="col-2 text-center">{list?.amount}</span>
                    <span className="col-2 ">
                      {list?.status == 1 && (
                        <img
                          src={SuccessIcon}
                          alt="Success Icon"
                          className="img-fluid"
                        />
                      )}
                    </span>
                  </span>
                }
              >
                <div>
                  <div className="row p-2">
                    <div className="col-4 d-flex flex-column">
                      <span className="primary-head fw-bold">
                        Transaction ID
                      </span>
                      <span className="blue-head">{list?.transaction_id}</span>
                    </div>
                    <div className="col-4 d-flex flex-column">
                      <span className="primary-head fw-bold">
                        No. of Payment
                      </span>
                      <span className="blue-head">04</span>
                    </div>
                    <div className="col-4">
                      <button className="btn blue__btn">
                        PDF <i className="bi bi-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            ))}
          </Accordion>
          <Paginator
            first={first}  // Pass the first row index to Paginator
            rows={rows}
            totalRecords={count}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
}
