import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    haveUser:false,
    userDetails:{       
    }

}
const dummyUserSlicer = createSlice({
        name: "dummyUser",
        initialState,
        reducers: {
          
          addUserData(state, action) {
            state.haveUser =action.payload?.haveUser;
            state.userDetails =action.payload?.userDetails
            
          },
          reset(state){
            return initialState
          }
        },
      });
      
      export const {  addUserData,reset } = dummyUserSlicer.actions;
      export const dummyUserReducer = dummyUserSlicer.reducer;
