import { createSlice } from '@reduxjs/toolkit';
const initialData= {
  type: "monthly",
  amount: 5000,
  rate: 12,
  time: 5,
}
const initialState = {
  tableView: false,
  initialData: initialData,
  result: [],
  report: [],
};

const sipSlice = createSlice({
  name: "sip",
  initialState,
  reducers: {
    toggleTableView(state, action) {
      state.tableView = action.payload;
    },
    updateInitialData(state, action) {
      state.initialData = {...action.payload.initialData};
      state.result =action.payload?.result;
      state.report =action.payload?.report
      state.tableView = action.payload.tableView
      
    },
    reset(state){
      console.log(initialState)
      return initialState
    }
  },
});

export const { toggleTableView, updateInitialData,reset } = sipSlice.actions;
export const sipReducer = sipSlice.reducer;
