import React from 'react';

import LeftArrow from '../../../assets/img/home/left-arrow-icon.svg'
import RightArrow from '../../../assets/img/home/right-arrow-icon.svg'

const CustomLeftArrow = ({ onClick }) => {
  return (
    <button className="custom-arrow custom-left-arrow mt-5" onClick={onClick}>
      <img src={LeftArrow} alt="left arrow" />
    </button>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <button className="custom-arrow custom-right-arrow mt-5" onClick={onClick}>
      <img src={RightArrow} alt="right arrow" />
    </button>
  );
};

export { CustomLeftArrow, CustomRightArrow };
