import { useState, useEffect, useRef } from "react";
import { Form, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

import LoginIcon from "../../assets/img/user/registration-icon.png";
import HeadContent from "../../UI/HeadContent/HeadContent";
import Input from "../../UI/Input/Input";
import "./Login.css";
import { postCall } from "../../services/APICall";
import ToastComponent from "../../UI/Toast/Toast";
import { Dialog } from "primereact/dialog";
import FormBtn from "../../UI/Button/FormBtn/FormBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { caesarDecrypt, caesarEncrypt } from "../../utils/Encryption";
export default function Login() {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const {
    register: forgotRegister,
    handleSubmit: forgotHandleSubmit,
    formState: { errors: forgotErrors },
    reset: forgotReset,
  } = useForm();
  const {
    register: forgotRegisterTwo,
    handleSubmit: forgotHandleSubmitTwo,
    formState: { errors: forgotErrorsTwo },
  } = useForm();
  const {
    register: forgotRegisterThree,
    handleSubmit: forgotHandleSubmitThree,
    formState: { errors: forgotErrorsThree },
  } = useForm();
  const [loginPwsMode, setLoginPwsMode] = useState(true);
  const [toastContent, setToastContent] = useState({ show: false });
  const [otp, setOtp] = useState("");
  const [forgotOtp, setForgotOtp] = useState("");
  const [accessOtp, setAccessOtp] = useState(false);
  const [timer, setTimer] = useState(59);
  const [hasStarted, setHasStarted] = useState(false);
  const [timerComplete, setTimerComplete] = useState(false);
  const [username, setUsername] = useState();
  const [forgotDialogData, setForgotDialogData] = useState({
    loading: false,
    show: false,
    username: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const rememberRef = useRef(false);

  /* TIMER */
  useEffect(() => {
    if (accessOtp) {
      setTimer(59);
      setHasStarted(true);
      setTimerComplete(false); // Reset timer complete state
    }
  }, [accessOtp]);
  /* REMEMBER ME DATA */
  useEffect(()=>{
    const rememberData =  localStorage.getItem("login__data");
    if(rememberData){
     const decryptedData = JSON.parse(caesarDecrypt(rememberData));
     reset(decryptedData)
    }

  },[])

  useEffect(() => {
    if (timer > 0 && hasStarted && !timerComplete) {
      const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeoutId);
    } else if (timer === 0) {
      setTimerComplete(true);
    }
  }, [timer, hasStarted, timerComplete]);

  const handleToastClose = () => {
    setToastContent((prev) => ({ ...prev, show: false }));
  };

  const loginWithPwdsHandler = async (data) => {
    try {
      console.log(rememberRef.current.checked);
      if(rememberRef.current.checked){
        const encryptData = caesarEncrypt(JSON.stringify(data))
        localStorage.setItem("login__data",encryptData)

      }
      const { response, result } = await postCall("login", data);
      if (response.ok) {
        sessionStorage.setItem("user", JSON.stringify(result));
        navigate("/user/dashboard");
      } else {
        throw new Error(result?.error || "Login failed");
      }
    } catch (error) {
      console.error(error.message);
      window.scrollTo(0, 0);
      setToastContent({
        show: true,
        message: error.message,
        type: "error",
        title: "Error",
      });
    }
  };

  /* request OTP Handler */
  const sendOTPHandler = async (data) => {
    setUsername(data);
    try {
      const { response, result } = await postCall("login-with-otp", data);
      if (response.ok) {
        setAccessOtp(true);
        setOtp();
        setHasStarted(true);
        setTimerComplete(true);
        // localStorage.setItem("user", JSON.stringify(result));
        // navigate("/user");
      } else {
        throw new Error(result?.error || "Login failed");
      }
    } catch (error) {
      console.error(error.message);
      window.scrollTo(0, 0);
      setToastContent({ show: true, message: error.message, type: "error" });
    }
  };

  /* LOGIN WITH OTP */
  const loginWithOtpHandler = async (data) => {
    const payload = {
      username: data.username,
      otp,
    };
    try {
      const { response, result } = await postCall("verify-otp", payload);
      if (response.ok) {
        sessionStorage.setItem("user", JSON.stringify(result));
        navigate("/user/dashboard");
      } else {
        throw new Error(result?.error || "Login failed");
      }
    } catch (error) {
      console.error(error.message);
      window.scrollTo(0, 0);
      setToastContent({ show: true, message: error.message, type: "error" });
    }
  };

  const loginHandler = async (data) => {
    if (loginPwsMode) {
      await loginWithPwdsHandler(data);
    } else {
      if (!accessOtp) {
        await sendOTPHandler(data);
      } else {
        await loginWithOtpHandler(data);
      }
    }
    // Add OTP login logic here if needed
  };

  const otpFirstHandler = async (data) => {
    console.log(data);
    try {
      setForgotDialogData({
        ...forgotDialogData,
        loading: true,
        username: data.username,
      });
      const { response, result } = await postCall("forgot-password", data);
      console.log(response, result);
      if (response.ok) {
        setForgotDialogData({
          ...forgotDialogData,
          loading: false,
          status: 2,
          username: result.email,
        });
      } else {
        setForgotDialogData({
          ...forgotDialogData,

          show: false,
          status: 1,
          loading: false,
        });
        setToastContent({
          ...forgotDialogData,

          show: true,
          message: result.error,
          type: "error",
          title: "Error",
        });
      }
    } catch (error) {
      setForgotDialogData({
        ...forgotDialogData,

        show: false,
        status: 1,
        loading: false,
      });
      console.error(error.message);
      window.scrollTo(0, 0);
      setToastContent({
        show: true,
        message: error.message,
        type: "error",
        title: "Error",
      });
    }
    forgotReset();
  };
  const forgotPwdSecondHandler = async (data) => {
    try {
      const payload = {
        username: forgotDialogData.username,
        otp: forgotOtp,
      };
      setForgotDialogData({
        ...forgotDialogData,
        loading: true,
      });
      const { response, result } = await postCall(
        "forgot-password-verify",
        payload,
        false
      );
      console.log(response, result);
      if (response.ok) {
        setForgotDialogData({
          ...forgotDialogData,
          loading: false,
          status: 3,
        });
      } else {
        setForgotDialogData({
          ...forgotDialogData,

          show: false,
          status: 1,
          loading: false,
        });
        setToastContent({
          show: true,
          message: result.error,
          type: "error",
          title: "Error",
        });
      }
    } catch (error) {
      setForgotDialogData({
        ...forgotDialogData,

        show: false,
        status: 1,
        loading: false,
      });
      console.error(error.message);
      window.scrollTo(0, 0);
      setToastContent({
        show: true,
        message: error.message,
        type: "error",
        title: "Error",
      });
    }
    setForgotOtp("");
  };

  
  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    if (password.length >= 12) score += 1;
    if (/[a-z]/.test(password)) score += 1;
    if (/[A-Z]/.test(password)) score += 1;
    if (/\d/.test(password)) score += 1;
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    switch (score) {
      case 0:
      case 1:
      case 2:
        return "Weak";
      case 3:
        return "Medium";
      case 4:
      case 5:
        return "Strong";
      default:
        return "";
    }
  };

  const [err_pwd, setErr_pwd] = useState(false);
  const forgotPwdThirdHandler = async (data) => {
    if (
      passwordStrength != "Strong" ||
      new_password.length == 0 ||
      confirm_password != new_password
    ) {
      setErr_pwd(true);
      console.log(
        "true",
        passwordStrength,
        new_password.length,
        confirm_password
      );
      return;
    } else {
      setErr_pwd(false);
      const confirm_password = data.confirm_password;
      if (passwordStrength) {
        try {
          console.log(forgotDialogData);
          const payload = {
            username: forgotDialogData.username,
            // otp: forgotOtp,
            password: new_password, // Adding new_password in the payload
          };
          console.log(payload);
          setForgotDialogData({
            ...forgotDialogData,
            loading: true,
          });
          const { response, result } = await postCall(
            "forgot-password-update",
            JSON.stringify(payload),
            false
          );
          console.log(response, result);
          if (response.ok) {
            setForgotDialogData({
              ...forgotDialogData,
              loading: false,
              status: 1,
              show: false,
            });
            setToastContent({
              show: true,
              message: result.data,
              type: "success",
              title: "Success",
            });
          } else {
            setForgotDialogData({
              ...forgotDialogData,
              show: false,
              status: 1,
              loading: false,
            });
            setToastContent({
              show: true,
              message: result.error,
              type: "error",
              title: "Error",
            });
          }
        } catch (error) {
          setForgotDialogData({
            ...forgotDialogData,

            show: false,
            status: 1,
            loading: false,
          });
          console.error(error.message);
          window.scrollTo(0, 0);
          setToastContent({
            show: true,
            message: error.message,
            type: "error",
            title: "Error",
          });
        }
      } else {
        return;
      }
    }
    setNew_password("");
    setConfirm_password("");
  };

  return (
    <>
      <div className="form-container red-light-bg p-5">
        <ToastComponent
          className={toastContent?.type === "error" ? "error-toast" : ""}
          type={toastContent?.type}
          show={toastContent.show}
          title={toastContent.title}
          message={toastContent?.message}
          onClose={handleToastClose}
        />

        <div style={{ maxWidth: "400px" }} className="w-100 mx-auto">
          <HeadContent>
            <span className="fs-2">User </span>
            <span className="fs-2 red-head">Login</span>
          </HeadContent>
          <p>Please enter your details to log into the dashboard</p>

          <div className="d-flex gap-2 login-tap-container  flex-column flex-sm-row">
            <button
              className={`btn w-100 ${loginPwsMode ? "active" : ""}`}
              onClick={() => setLoginPwsMode(true)}
            >
              Login with Password
            </button>
            <button
              className={`btn w-100 ${!loginPwsMode ? "active" : ""}`}
              onClick={() => setLoginPwsMode(false)}
            >
              Login with OTP
            </button>
          </div>

          <form onSubmit={handleSubmit(loginHandler)} className="mt-3">
            <Input
              id="username"
              label="Email or Mobile"
              type="text"
              register={register}
              validation={{ required: "Provide a valid input" }}
              errors={errors}
              disabled={accessOtp}
            />
            {loginPwsMode && (
              <>
                <Input
                  id="password"
                  label="Password"
                  type="password"
                  register={register}
                  validation={{ required: "Enter your password" }}
                  errors={errors}
                />
                <div className="d-flex justify-content-between">
                  <div className="no-wrap">
                    <label htmlFor="remember-me" className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value="false"
                        id="remember-me"
                        className="me-2"
                        ref={rememberRef}
                      />                      
                      Remember me

                    </label>
                  </div>
                  <button
                    className="forgot-password__btn"
                    type="button"
                    onClick={() => {
                      setForgotDialogData({
                        ...forgotDialogData,
                        show: true,
                        status: 1,
                      });
                    }}
                  >
                    Forgot Password
                  </button>
                </div>
              </>
            )}
            {!loginPwsMode && (
              <>
                {!accessOtp && (
                  <div className="text-center">
                    <button className="btn blue__btn ">Send OTP</button>
                  </div>
                )}
                {accessOtp && (
                  <div>
                    <p>Enter OTP</p>
                    <div className="otp-inputs-container">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <p className="d-flex align-items-center">
                        Didn’t receive OTP?{" "}
                        <button
                          className="btn px-1 otp__btn"
                          disabled={timer > 0}
                          onClick={() => {
                            sendOTPHandler(username);
                          }}
                        >
                          <span className="red-head">Resend</span>
                        </button>
                      </p>
                      <span>00:{timer}</span>
                    </div>
                  </div>
                )}
              </>
            )}

            {loginPwsMode && (
              <div className="text-center">
                <button className="btn red__btn px-5 my-2 mx-auto">
                  Login
                </button>
              </div>
            )}
            {!loginPwsMode && accessOtp && otp?.length === 4 && (
              <div className="text-center">
                <button className="btn red__btn px-5 my-2 mx-auto">
                  Login
                </button>
              </div>
            )}
          </form>

          <div className="text-center">
            <HeadContent className="text-center">
              <span className="fs-6">Don’t have an account? </span>
              <NavLink
                to="/auth/register"
                className="fs-6 red-head text-decoration-none"
              >
                Sign Up
              </NavLink>
            </HeadContent>
          </div>
        </div>
      </div>
      <Dialog
        visible={forgotDialogData.show}
        className="forgot-password__dialog"
        style={{ width: "350px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setForgotDialogData({ ...forgotDialogData, show: false })} // Ensure dialog closes correctly
      >
        <div className="forgot-dialog__container p-2">
          <div className="text-end">
            <button
              className="forgot-password__dialog--btn"
              onClick={() =>
                setForgotDialogData({ ...forgotDialogData, show: false })
              }
            >
              X
            </button>
          </div>
          <p className="secondary-blue-head fw-bold fs-4 text-center">
            Forgot <span className="red-head">Password</span>
          </p>

          {forgotDialogData.status === 1 && (
            <form
              onSubmit={forgotHandleSubmit(otpFirstHandler)}
              className="mt-3 px-3"
            >
              <Input
                id="username"
                label="Email or Mobile"
                type="text"
                register={forgotRegister}
                validation={{ required: "Provide a valid input" }}
                errors={forgotErrors}
                disabled={accessOtp}
              />
              <div className="text-center">
                {/* <button className="btn red__btn px-3" type="submit">
                  Continue
                </button> */}
                <FormBtn
                  isLoading={forgotDialogData.loading}
                  className="red__btn"
                  type="submit"
                >
                  Continue
                </FormBtn>
              </div>
            </form>
          )}
          {/* onSubmit={forgotHandleSubmitTwo(forgotPwdThirdHandler)} */}
          {forgotDialogData.status === 2 && (
            <form className="mt-3 px-3">
              <div>
                <p>Enter OTP</p>
                <div className="otp-inputs-container">
                  <OtpInput
                    value={forgotOtp}
                    onChange={setForgotOtp}
                    numInputs={4}
                    inputType="number"
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <p className="d-flex align-items-center">
                    Didn’t receive OTP?{" "}
                    <button
                      className="btn px-1 otp__btn"
                      disabled={timer > 0}
                      onClick={() => {
                        sendOTPHandler(username);
                      }}
                    >
                      <span className="red-head">Resend</span>
                    </button>
                  </p>
                  <span>00:{timer}</span>
                </div>
              </div>
              {forgotOtp.length === 4 && (
                <div className="text-center">
                  {/* <button className="btn red__btn px-3" type="submit">
                  Continue
                </button> */}
                  <FormBtn
                    isLoading={forgotDialogData.loading}
                    className="red__btn"
                    type="button"
                    onClick={forgotPwdSecondHandler}
                  >
                    Submit OTP
                  </FormBtn>
                </div>
              )}
            </form>
          )}
          {forgotDialogData.status === 3 && (
            <form
              onSubmit={forgotHandleSubmitThree(forgotPwdThirdHandler)}
              className="mt-3 px-3"
            >
              {/* <Input
              id="new_password"
              label="New Password"
              type="password"
              register={forgotRegisterThree}
              validation={{ required: "Enter your new password" }}
              errors={forgotErrorsThree}
            />
          
            <Input
              id="confirm_password"
              label="Confirm Password"
              type="password"
              register={forgotRegisterThree}
              validation={{
                required: "Confirm your password",
                validate: (value) =>
                  value === forgotRegisterThree.getValues('new_password') || "Passwords do not match",
              }}
              errors={forgotErrorsThree}
            />
           */}

              <div className="input-controller mb-3">
                <label htmlFor="new_password">New Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="new_password"
                    className="form-control"
                    value={new_password}
                    onChange={(e) => {
                      setNew_password(e.target.value);
                      setPasswordStrength(
                        evaluatePasswordStrength(e.target.value)
                      );
                      console.log(new_password, passwordStrength);

                      // forgotHandleSubmitThree(e.target.value)
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="toggle-password-visibility"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "6px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#F58220" }}
                      icon={showNewPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
                {err_pwd && new_password == "" && (
                  <p className="red-head">Provide a stronger password</p>
                )}
                {new_password != "" && passwordStrength !== "Strong" && (
                  <p className="red-head">Provide a stronger password</p>
                )}
                <p>
                  Password Strength: <strong>{passwordStrength}</strong>
                </p>
                <p className="red-head">
                  Please add all necessary characters to create a safe password.
                </p>
                <p>
                  Minimum characters 12, One uppercase character, One lowercase
                  character, One special character, One number.
                </p>
              </div>

              <div className="input-controller">
                <label htmlFor="confirm_password">Confirm Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    className="form-control"
                    value={confirm_password}
                    onChange={(e) => setConfirm_password(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="toggle-password-visibility"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "6px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#F58220" }}
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
                {(errors.confirm_pass_err ||
                  new_password !== confirm_password ||
                  (confirm_password == "" && new_password)) && (
                  <p className="red-head">Passwords do not match</p>
                )}
              </div>

              <div className="text-center my-2">
                <FormBtn
                  isLoading={forgotDialogData.loading}
                  className="red__btn"
                  type="submit"
                  onClick={forgotHandleSubmitThree}
                >
                  Submit
                </FormBtn>
              </div>
            </form>
          )}
        </div>
      </Dialog>
    </>
  );
}
