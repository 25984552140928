import React from "react";

const Input = ({
  id,
  label,
  type = "text",
  placeholder,
  register,
  validation,
  errors,
  disabled,
  onChange,
  options = [], // Add options prop for select input
  maxLength,    // Optional maxLength prop for text inputs
  max,          // Optional max prop for numeric inputs
  min,          // Optional min prop for numeric inputs
}) => {
  return (
    <>
      {id !== "contact" && type !== "select" && (
        <div className="form-group mb-2">
          <label htmlFor={id} className="mb-1">{label}</label>
          <input
            placeholder={placeholder}
            id={id}
            type={type}
            className={`form-control ${errors[id] ? "is-invalid" : ""}`}
            {...(register && register(id, validation))} // Ensure register is a function before calling
            aria-invalid={errors[id] ? "true" : "false"}
            disabled={disabled}
            onChange={onChange}
            maxLength={maxLength} // Set maxLength attribute
            max={max}             // Set max value attribute for numeric inputs
            min={min}             // Set min value attribute for numeric inputs
          />
          {errors[id] && (
            <p className="invalid-feedback">{errors[id]?.message}</p>
          )}
        </div>
      )}
      {id === "contact" && (
        <div className="form-group mobile-input mb-2">
          <label htmlFor={id} className="w-100 mb-1">
            {label}
          </label>
          <div className="form-control">
            <input
              type="text"
              value="+91"
              id="country-code"
              className=" d-inline-block"
              readOnly
              style={{ width: "60px" }}
            />
            <input
              placeholder={placeholder}
              id={id}
              type={type}
              className={`d-inline-block  ${errors[id] ? "is-invalid" : ""}`}
              {...register(id, validation)}
              aria-invalid={errors[id] ? "true" : "false"}
              maxLength={maxLength} // Set maxLength attribute
              max={max}             // Set max value attribute for numeric inputs
              min={min}             // Set min value attribute for numeric inputs
            />
          </div>
          <p className="invalid-feedback d-block">{errors[id]?.message}</p>
        </div>
      )}
      {type === "select" && (
        <div className="form-group mb-2">
          <label htmlFor={id} className="mb-1">{label}</label>
          <select
            id={id}
            className={`form-control ${errors[id] ? "is-invalid" : ""}`}
            {...(register && register(id, validation))}
            aria-invalid={errors[id] ? "true" : "false"}
            disabled={disabled}
            onChange={onChange}
          >
            <option value="">{placeholder}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors[id] && (
            <p className="invalid-feedback">{errors[id]?.message}</p>
          )}
        </div>
      )}
    </>
  );
};

export default Input;
