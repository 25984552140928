import React, { useState } from "react";
import { convertDateFormat, addDaysToDate } from "../../../utils/date";
import "./InvestCard.css";
import { formatToINR } from "../../../utils/optimize";

export default function InvestCard({ list, takeLoanHandler }) {
  const [isExpanded, setIsExpanded] = useState(false); // State for expansion

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Toggle expansion state
  };

  const start_date = convertDateFormat(list?.created_at);

  return (
    <>
      <section className="invest-card-container my-2 py-2">
        <div className="invest__card card p-0">
          <div className="invest__card-head">
            <div className="invest-id">{list?.inv_code}</div>
            <div className="invest-status active">
              <i className="bi bi-patch-check"></i>
              <span>Active</span>
            </div>
          </div>
          <div className="invest__card-body row m-0">
            <div className="section col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="label">
                {list?.pay_type === "onetime" ? "One Time" : "Monthly"}
              </div>
              <div className="value">{formatToINR(list?.pay_amount)}</div>
            </div>
            <div className="section col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="label">Current Returns</div>
              <div className="value">{formatToINR(list?.amount)}</div>
            </div>
            <div className="section col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="label">Start Date</div>
              <div className="value">{start_date}</div>
            </div>
            <div className="section col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="label">Renewal Date</div>
              <div className="value red-head">
                {addDaysToDate(start_date, 30)}
              </div>
            </div>
            <div className="section col-lg-2 col-md-4 col-sm-6 col-12">
              <div className="label">Payments</div>
              <div className="value">4/60</div>
            </div>
            <div className="section section-btn col-lg-2 col-md-4 col-sm-6 col-12 py-1 text-end">
              <button className="btn red__btn">Pay now</button>
              <a
                onClick={toggleExpand}
                className="d-block my-2 fw-bold text-transform-none   blue-head"
                style={{ cursor: "pointer" }}
              >
                {isExpanded ? (
                  <span className="d-flex justify-content-end align-items-center">
                    <span>Collapse</span>
                    <i className="bi bi-caret-up-fill"></i>
                  </span>
                ) : (
                  <span className="d-flex justify-content-end align-items-center">
                    <span>
                      View
                    </span>
                      <i className="bi bi-caret-down-fill"></i>
                  </span>
                )}
              </a>
            </div>
          </div>
          {isExpanded && (
            <div className="invest__card-footer row justify-content-end">
              <div className="section d-flex me-auto col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="label">Nominee :</div>
                <div className="value">{list?.nominee[0].relationship}</div>
              </div>
              <div className="section section-btn col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <button className="btn red__btn" onClick={takeLoanHandler}>
                  Take Loan
                </button>
              </div>
              <div className="section section-btn col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <button className="btn dark-blue__btn">View History</button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
