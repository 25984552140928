export default function Terms() {
  return (
    <>
      <div className="red-light-bg p-3 px-5" style={{ minHeight: "68Vh" }}>
        <h3 className="text-center p-3 fs-2 blue-head">Terms & Conditons</h3>
        <ul className="document-list">
          <li>
            <p>
              In addition to the disclosures reasonably necessary for the
              purposes identified elsewhere in this privacy policy, we may
              disclose information about you:
            </p>
          </li>
          <li>
            <p>To the extent that we are required to do so by law.</p>
          </li>
          <li>
            <p>
              In connection with any legal proceedings or prospective legal
              proceedings;
            </p>
          </li>
          <li>
            <p>
              In order to establish, exercise or defend our legal rights
              (including providing information to others for the purposes of
              fraud prevention and reducing credit risk); and
            </p>
          </li>
          <li>
            <p>
              To the purchaser (or prospective purchaser) of any business or
              asset which we are (or are contemplating) selling
            </p>
          </li>
          <li>
            <p>
              Except as provided in this privacy policy, we will not provide
              your information to third parties.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}
