import './Footer.css';

/* images */
import signupIcon from '../../assets/img/sign-up-icon.svg';
import FooterIcon1 from '../../assets/img/fot-icon-2.svg';
import FooterIcon2 from '../../assets/img/fot-icon-2.svg';
import FooterIcon3 from '../../assets/img/fot-icon-3.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAuthToken } from '../../utils/auth';


export default  function Footer(){
  const location = useLocation();
  const navigate =useNavigate();
  const [mainFooterOnly,setMainFooterOnly] = useState(false)
  const { pathname } = location;
  const token = getAuthToken()

  useEffect(() => {
    if (
      pathname === '/' ||
      pathname === '/sip' ||
      pathname === '/about' ||
      pathname === '/plan' ||
      pathname === '/sip-calculation' ||
      pathname === '/contact'||
      pathname === '/privacy'||
      pathname === '/terms'
    ) {
      setMainFooterOnly(false);
    } else {
      setMainFooterOnly(true);
    }
  }, [pathname]);
  
    
    return(
        <>
        {/* <!-- START FOOTER --> */}
    <footer className="p-0">
      {!mainFooterOnly&&<>
        {/* {!token&& */}
        <div className="footer-first__container mx-auto">
          <div className="row">
            <div className="col-sm-8 section-1 p-3 py-4">
              Start your investing journey today.
            </div>
            <div className="col-sm-4 section-2  text-center ">
              <img
                src={signupIcon}
                className="img-fluid sign-up-icon"
              />
              <button className="btn w-100 h-100 p-3 py-4 shutter_btn hvr-shutter-out-vertical" onClick={()=>{token?navigate('/user/dashboard'):navigate('/auth/register')}}>{token? 'Go to Dashboard':'Sign Up to Explore'} </button>
            </div>
          </div>
        </div>
        {/* } */}
        <div className="footer-second__container p-3 py-md-3 py-1 pb-0">
          <div className="container">
            <div className="row align-items-center py-4 pb-2">
              <div className="col-md-4">
                <div className="d-flex align-items-center gap-2 sec-1 sec contact-cont wobble-horizontal-on-hover">
                  <img src={FooterIcon1} alt="call " />
                  <a href="tel:+919632499099">(+91) 96324 99099</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center gap-2 sec-2 sec contact-cont wobble-horizontal-on-hover">
                  <img src={FooterIcon2} alt="call " />
                  <a href="mailto:thefalcon-sip@gmail.com">thefalcon-sip@gmail.com</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center gap-2 sec-3 sec contact-cont wobble-horizontal-on-hover">
                  <img src={FooterIcon3} alt="call " />
                  <span
                    >#12, Rajaji Street,
                    <br />
                    Trichy Main Road, Namakkal - 637001.</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
      <div className="footer-third__container p-3">
        <div className="container-fluid">
          <div
            className="d-flex gap-0 flex-wrap-reverse flex-lg-nowrap align-items-center justify-content-lg-between justify-content-center text-center"
          >
            <p className="d-lg-block d-none">
              Copyright © 2024 The Falcon. All Rights Reserved. | Website
              Developed By <a href="https://proz.in/" target="_blank">ProZ Solutions LLP</a>.
            </p>
            <p className="d-block d-lg-none">
              Copyright © 2024 The Falcon. All Rights Reserved. <br/> Website
              Developed By ProZ Solutions LLP.
            </p>
            <p>
              <a  onClick={()=>{navigate('/terms')}} className='text-decoration-none cursor-pointer' style={{cursor:"pointer"}}>Terms and conditions </a> /
              <a  onClick={()=>{navigate('/privacy')}} className='text-decoration-none cursor-pointer' style={{cursor:"pointer"}}>
              Privacy and return Policy
              </a>
              </p>
          </div>
        </div>
      </div>
    </footer>
    {/* <!-- END FOOTER --> */}
        </>
    )
}