import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import About from './pages/About/About';
import Plan from './pages/Plan/Plan';
import SIP from './pages/SIP/SIP';
import Contact from './pages/Contact/Contact';
import SipCalculation from './pages/SipCalculation/SipCalculation';
import Registration from './components/Registration/Registration';
import Login from './components/Login/Login'
import AuthLayout from './pages/AuthLayout';
import Dashboard from './pages/Dashboard/Dashboard';
import UserLayout from './pages/UserLayout';
import { getAuthToken, tokenLoader } from './utils/auth';
import Investment from './pages/Investment/Investment';
import Claim from './pages/Claim/Claim';
import Profile from './pages/Profile/Profile';
import PaymentHistory from './pages/PaymentHistory/PaymentHistory';
import Password from './pages/Password/Password';
import Loan from './pages/Loan/Loan';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';

function App() {
  const token = getAuthToken()
  const router = createBrowserRouter([
    {
      path:'/',
      element:<RootLayout />,
      id:'root',
      loader:tokenLoader,
      children:[
        {index:true, element:<Home />},
        {path:'/about', element:<About />},
        {path:'/plan', element:<Plan />},
        {path:'/sip', element:<SIP />},
        {path:'/contact', element:<Contact />},
        {path:'/sip-calculation', element:<SipCalculation />},
        {path:'/privacy', element:<Privacy />},
        {path:'/terms', element:<Terms />},
        {
          path:'auth/',
          element:<AuthLayout />,
          children:[
            {index:true,element:<Login/>},
            {path:'login',element:<Login/>},
            {path:'register',element:<Registration/>},

          ]
        },
        {
          path:'user/',
          element:<UserLayout />,
          children:[
            {index:true, element:<Dashboard />},
            {path:'dashboard', element:<Dashboard />},
            {path:'investment', element:<Investment />},
            {path:'claim', element:<Claim />},
            {path:'loan', element:<Loan />},
            {path:'payment-history', element:<PaymentHistory />},
            {path:'profile', element:<Profile />},
            {path:'password', element:<Password />},
          ]
        }

      ]
    }
  ])
  
    return <RouterProvider router={router} />;

  
}

export default App;
