export function getLocalStorage(){
    const userData =JSON.parse(sessionStorage.getItem('user'));
    return userData;
    
}

export function getAuthToken(){
    const userData =JSON.parse(sessionStorage.getItem('user'));
    return userData?.bearer_token || null;

}

export function tokenLoader(){
    return getAuthToken()
}

export function logout(){
    sessionStorage.removeItem('user');
    window.location.href = '/auth/login'; // Redirect to login page after logout


}