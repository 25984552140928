import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import ProfileIcon from "../../assets/img/user-dashboard/profile.svg";
import ProfileIconActive from "../../assets/img/user-dashboard/lock-active.svg";
import { LoggedpostCall } from "../../services/APICall";
import ToastComponent from "../../UI/Toast/Toast";

export default function Password() {
  const navigate = useNavigate();

  const [current_password, setCurrent_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [errors, setErrors] = useState({
    old_pass_err: false,
    new_pass_err: false,
    confirm_pass_err: false,
  });

  const [toastData, setToastData] = useState({ show: false });

  const validatePassword = () => {
    let valid = true;
    let errors = {
      old_pass_err: !current_password,
      new_pass_err: !new_password || passwordStrength === "Weak",
      confirm_pass_err: confirm_password !== new_password,
    };

    if (errors.old_pass_err || errors.new_pass_err || errors.confirm_pass_err) {
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (validatePassword()) {
      // Handle the password change logic here
      console.log({
        current_password,
        new_password,
        confirm_password,
      });
      const payload = {
        current_password,
        new_password,
      };
      console.log(payload)
      const { response, result } = await LoggedpostCall(
        "change-password",
        JSON.stringify(payload),
        false
        
      );
      if (response.ok) {
        setToastData({
          show:true,
          type:result?.status,
          message:result?.data,
          title:"Success"
        })

      } else {
        console.log(result);
        setToastData({
          show:true,
          type:result?.status,
          message:result?.data,
          title:"Error"
        })
      }
    }
  };

  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    if (password.length >= 12) score += 1;
    if (/[a-z]/.test(password)) score += 1;
    if (/[A-Z]/.test(password)) score += 1;
    if (/\d/.test(password)) score += 1;
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    switch (score) {
      case 0:
      case 1:
      case 2:
        return "Weak";
      case 3:
        return "Medium";
      case 4:
      case 5:
        return "Strong";
      default:
        return "";
    }
  };

  const handlePasswordChange = async (e) => {
    const password = e.target.value;
    const strength = evaluatePasswordStrength(password);
    setPasswordStrength(strength);
  };

  return (
    <>
     <ToastComponent
        className={toastData.type === "error" ? "error-toast" : "success-toast"}
        show={toastData?.show}
        type={toastData.type}
        message={toastData.message}
        title={toastData.title}
      />
      <div className="user-info-head d-flex justify-content-between">
        <div className="user-info-head__content-container">
          <div className="head">Settings</div>
          <div className="content text-nowrap">
            Manage your personal & contact details
          </div>
        </div>
        <div className="user-info-head__tab-container d-flex w-100 justify-content-end">
          <button className="btn" onClick={() => navigate("/user/profile")}>
            <img src={ProfileIcon} alt="Profile" />
            <span>Profile</span>
          </button>
          <button
            className="btn active"
            onClick={() => navigate("/user/password")}
          >
            <img src={ProfileIconActive} alt="Change Password" />
            Change Password
          </button>
        </div>
      </div>

      <div className="profile-container">
        <div className="card">
          <div className="card-head">
            <p>Change password</p>
          </div>
          <div className="card-body">
            <p className="secondary-blue-head fw-bold">
              Create Your New Password!
            </p>

            <form onSubmit={changePasswordHandler} style={{ width: "350px" }}>
              <div className="input-controller mb-3">
                <label htmlFor="current_password">Old Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showOldPassword ? "text" : "password"}
                    id="current_password"
                    className="form-control"
                    value={current_password}
                    onChange={(e) => setCurrent_password(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    className="toggle-password-visibility"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "6px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#F58220" }}
                      icon={showOldPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
                {errors.old_pass_err && (
                  <p className="red-head">Provide Valid Password</p>
                )}
              </div>

              <div className="input-controller mb-3">
                <label htmlFor="new_password">New Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="new_password"
                    className="form-control"
                    value={new_password}
                    onChange={(e) => {
                      setNew_password(e.target.value);
                      handlePasswordChange(e);
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="toggle-password-visibility"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "6px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#F58220" }}
                      icon={showNewPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
                {errors.new_pass_err && (
                  <p className="red-head">Provide a stronger password</p>
                )}
                <p>
                  Password Strength: <strong>{passwordStrength}</strong>
                </p>
                <p className="red-head">
                  Please add all necessary characters to create a safe password.
                </p>
                <p>
                  Minimum characters 12, One uppercase character, One lowercase
                  character, One special character, One number.
                </p>
              </div>

              <div className="input-controller">
                <label htmlFor="confirm_password">Confirm Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    className="form-control"
                    value={confirm_password}
                    onChange={(e) => setConfirm_password(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="toggle-password-visibility"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "6px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#F58220" }}
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
                {(errors.confirm_pass_err ||
                  new_password !== confirm_password ||
                  confirm_password == "") && (
                  <p className="red-head">Passwords do not match</p>
                )}
              </div>

              <div>
                <button className="btn px-5 red__btn my-2">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
