// Calculate interest for a given balance and rate
const calculateMonthlyInterest = (balance, annualInterestRate) => (balance * annualInterestRate) / 1200;

// Calculate future value of SIP (Systematic Investment Plan)
const calculateSIPFutureValue = (principal, monthlyRate, periods) => {
  return principal * (((Math.pow(1 + monthlyRate, periods) - 1) / monthlyRate) * (1 + monthlyRate));
};

// Function to calculate investment for a one-time investment
const oneTimeInvest = (initial, interestRate, years) => {
  const totalMonths = years * 12;
  const list = [{ month: 0, interest: 0, accruedInterest: 0, balance: initial }];

  for (let i = 1; i <= totalMonths; i++) {
    const last = list[i - 1];
    const interestAccrued = calculateMonthlyInterest(last.balance, interestRate);
    const newBalance = Number(initial) + (Number(interestAccrued) + Number(last.accruedInterest));

    list.push({
      month: i,
      interest: interestAccrued.toFixed(2),
      accruedInterest: (Number(interestAccrued) + Number(last.accruedInterest)).toFixed(2),
      balance: newBalance.toFixed(2),
    });
  }

  // Optimize the data for yearly reporting
  const optimizedData = list.filter(item => item.month === 0 || item.month % 12 === 0).map((item, index) => ({
    year: index,
    ...item,
  }));

  const result = {
    initial: Number(optimizedData[0]?.balance).toFixed(2),
    return: Number(optimizedData[optimizedData.length - 1].accruedInterest).toFixed(2),
    futureValue: Number(optimizedData[optimizedData.length - 1].balance).toFixed(2),
  };

  return {
    list: optimizedData,
    result,
  };
};

// Function to calculate investment for a monthly SIP
const monthlyInvest = (monthlyInvestment, annualInterestRate, totalYears) => {
  const monthlyRate = annualInterestRate / 1200;
  const report = [];
  let totalInvestment = 0;

  for (let year = 1; year <= totalYears; year++) {
    totalInvestment += monthlyInvestment * 12;
    const futureValue = calculateSIPFutureValue(monthlyInvestment, monthlyRate, year * 12);
    report.push({
      year,
      totalInvestment: totalInvestment.toFixed(2),
      futureValue: futureValue.toFixed(2),
    });
  }

  const result = {
    initial: report[report.length - 1].totalInvestment,
    return: (report[report.length - 1].futureValue - report[report.length - 1].totalInvestment).toFixed(2),
    futureValue: report[report.length - 1].futureValue,
  };

  return {
    list: report,
    result,
  };
};

module.exports = {
  oneTimeInvest,
  monthlyInvest,
};
