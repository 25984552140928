import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./SipCalculation.css";
import SIPCalculator from "../../components/SIPCalculator/SIPCalculator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTableView } from "../../slicer/sipSlicer";
import { tokenLoader } from "../../utils/auth";
import PlanIcon1 from "../../assets/img/retirement-plan/retirement-icon-1.svg";
import PlanIcon2 from "../../assets/img/retirement-plan/retirement-icon-2.svg";
import PlanIcon3 from "../../assets/img/retirement-plan/retirement-icon-3.svg";
import { formatToINR } from "../../utils/optimize";
import FormBtn from "../../UI/Button/FormBtn/FormBtn";
import { toggleInvestView } from "../../slicer/investSlicer";

export default function SipCalculation() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sipData, setSipData] = useState(null);
  const [tableView, setTableView] = useState(true);
  const intialDatas = useSelector((state) => state.sip);
  const [data, setData] = useState(intialDatas);
  // const sipData = location.state;
  useEffect(() => {
    //  setSipData(location.state)
    dispatch(toggleTableView(true));
    // sipBtnHandler();

    dispatch(toggleTableView(true));
  }, []);

  useEffect(() => {
    setData({
      ...intialDatas,
      tableView: true,
    });
  }, [intialDatas]);
  const sipBtnHandler = () => {

    dispatch(toggleTableView(true));
  };

  const proceedHandler = () => {
    const token = tokenLoader();
    if (token) {
      dispatch(toggleInvestView(true))
      navigate("/user/dashboard", { state: "invest" });
    } else {
      dispatch(toggleInvestView(true))

      navigate("/auth/register");
    }
  };

  return (
    <>
      {/* <!-- head-container --> */}
      <div className="top-head-container">
        <div className="container top-head-sub-container">
          <h2 className="head">Plans & Benefits</h2>
        </div>
        <div className="page-info">
          <NavLink to="/">Home</NavLink> /
          <NavLink to="/plan"> Plans & Benefits</NavLink> / SIP Calculator
        </div>
      </div>
      <div className="second__container red-light-bg py-md-5 pb-0">
        <div className="container">
          <h6 className="blue-head text-center fw-bold text--6xl mb-3">SIP Calculator</h6>
          <h6 className="blue-head text-center text--2xl mb-3">
            Recommended {data?.initialData?.type==="monthly"?"Monthly":"One Time"} SIP Amount 
            <span className="red-head"> {formatToINR(data?.initialData?.amount)} {data?.initialData?.type==="monthly"?"/Month":""}</span>
          </h6>
          <div className="py-md-4 pt-4 pb-1 sip-calculator-container">
            <div className="d-flex g-2 gap-4 gap-md-0 flex-wrap flex-lg-nowrap justify-content-center justify-content-lg-between">
              <div className="">
                <div className="sip-calculator mt-4 mt-lg-0">
                  <SIPCalculator
                    clickHandler={sipBtnHandler}
                    tableView={tableView}
                  />
                </div>
              </div>
              <div className="ms-4 w-100 w-md-75">
                <div className="plan-structure my-5">
                  {data?.tableView && (
                    <>
                      <h6 className="red-head fs-3 text-center my-2">
                        Your Plan Summary
                      </h6>
                      {data?.initialData?.type == "monthly" && (
                        <table className="plan-table" id="dataTable">
                          <thead>
                            <tr>
                              <td>Year</td>
                              <td>Invest Amount &#8377; </td>
                              <td>Balance &#8377; </td>
                            </tr>
                          </thead>
                          <tbody>
                            {intialDatas &&
                              intialDatas.report.map((list) => (
                                <tr>
                                  <td>{list.year}</td>
                                  <td>{formatToINR(list.totalInvestment,false)}</td>
                                  <td>{formatToINR(list.futureValue,false)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {data?.initialData?.type == "onetime" && (
                        <table className="plan-table" id="dataTable">
                          <thead>
                            <tr>
                              <td>Year</td>
                              <td>Interest &#8377; </td>
                              <td>Accrued interest &#8377; </td>
                              <td>Balance &#8377; </td>
                            </tr>
                          </thead>
                          <tbody>
                            {intialDatas &&
                              intialDatas?.report.map((list) => (
                                <tr>
                                  <td>{list.year}</td>
                                  <td>{formatToINR(list.interest,false)}</td>
                                  <td>{formatToINR(list.accruedInterest,false)}</td>
                                  <td>{formatToINR(list.balance,false)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      <div className="text-center my-3">
                        <FormBtn
                          className="btn blue__btn"
                          onClick={proceedHandler}
                        >
                          Proceed
                        </FormBtn>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="container">
          <h6 className="text-center blue-head fw-bold text--4xl">
            Why should you start planning for your
            <span className="red-head fw-bold"> Investment Today?</span>
          </h6>
          <div className="retirement-plan py-md-4 pb-0">
            <div className="d-none d-md-block">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <div className="d-flex align-items-center gap-2">
                            <img src={PlanIcon1} alt="More time in hand" />
                            <span>More time in hand</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <div className="d-flex align-items-center gap-2">
                            <img src={PlanIcon2} alt="Asset Allocation" />
                            <span>Asset Allocation</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <div className="d-flex align-items-center gap-2">
                            <img src={PlanIcon3} alt="Tax Efficient" />
                            <span>Tax Efficient</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <p className="fs-5 blue-head">More time in hand</p>
                        <p className="text__light">
                          A Falcon Asset Management Unit is a firm which ensures
                          the asset Management of an individual investors or an
                          institution or both. Our Management Unit manages the
                          portfolio of an individual ensuring them to enlighten
                          in different phase of life through our SIP plan.
                          <br />
                          <br />
                          Our Management Unit manages the portfolio of an
                          individual ensuring them to enlighten in different
                          phase of life through our SIP plan.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p className="fs-5 blue-head">Assets Allocation</p>
                        <p className="text__light">
                        Starting your investment journey today is crucial for long-term financial success. The sooner you begin, the more time your investments have to grow through the power of compounding. Early planning allows you to allocate assets more effectively, balancing risk and reward across equities, debt, and other investment options. It also helps you stay ahead of inflation and meet your future financial goals, like buying a home or retiring comfortably. By planning early, you can take advantage of market opportunities, make informed decisions, and build a robust portfolio that secures your financial future.
                          
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p className="fs-5 blue-head">Tax Efficient</p>
                        <p className="text__light">
                        Tax-efficient investing involves strategies that minimize your tax liabilities while maximizing returns. By choosing investments that offer tax advantages, such as tax-deferred accounts or tax-exempt bonds, you can keep more of your earnings. For example, utilizing accounts like IRAs or 401(k)s can defer taxes until withdrawal, while municipal bonds offer tax-free interest. Additionally, investing in index funds or ETFs can reduce taxable capital gains due to lower turnover. Planning and managing your investments with tax efficiency in mind helps to preserve wealth and enhance overall returns.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>

            {/* <!-- MOBILE VIEW --> */}
            <div className="accordion mt-4 d-block d-md-none">
              <Accordion activeIndex={0}>
                <AccordionTab header="India’s leaders">
                  <div>
                  <p className="fs-5 blue-head">More time in hand</p>
                        <p className="text__light">
                          A Falcon Asset Management Unit is a firm which ensures
                          the asset Management of an individual investors or an
                          institution or both. Our Management Unit manages the
                          portfolio of an individual ensuring them to enlighten
                          in different phase of life through our SIP plan.
                          <br />
                          <br />
                          Our Management Unit manages the portfolio of an
                          individual ensuring them to enlighten in different
                          phase of life through our SIP plan.
                        </p>
                  </div>
                </AccordionTab>
                <AccordionTab header="Asset Allocation">
                <div>
                  <p className="fs-5 blue-head">More time in hand</p>
                        <p className="text__light">
                          A Falcon Asset Management Unit is a firm which ensures
                          the asset Management of an individual investors or an
                          institution or both. Our Management Unit manages the
                          portfolio of an individual ensuring them to enlighten
                          in different phase of life through our SIP plan.
                          <br />
                          <br />
                          Our Management Unit manages the portfolio of an
                          individual ensuring them to enlighten in different
                          phase of life through our SIP plan.
                        </p>
                  </div>
                </AccordionTab>
                <AccordionTab header="Tax Efficient">
                <div>
                  <p className="fs-5 blue-head">More time in hand</p>
                        <p className="text__light">
                          A Falcon Asset Management Unit is a firm which ensures
                          the asset Management of an individual investors or an
                          institution or both. Our Management Unit manages the
                          portfolio of an individual ensuring them to enlighten
                          in different phase of life through our SIP plan.
                          <br />
                          <br />
                          Our Management Unit manages the portfolio of an
                          individual ensuring them to enlighten in different
                          phase of life through our SIP plan.
                        </p>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}
