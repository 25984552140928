import React from 'react';
import { Button, Spinner } from 'react-bootstrap'; // Assuming you're using React Bootstrap

import './FormBtn.css';

const FormBtn = ({ isLoading, children,className, ...props }) => {
    return (
        <Button {...props} disabled={isLoading} className={`form-btn hvr-shutter-out-vertical ${className}`}>
            {isLoading ? (
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">{children}</span>
                </>
            ) : (
                children
            )}
        </Button>
    );
};

export default FormBtn;
