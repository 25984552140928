import { NavLink, useNavigate } from "react-router-dom";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { Accordion, AccordionTab } from "primereact/accordion";

import "./Plan.css";

import Plan1 from "../../assets/img/plan/plan-1.svg";
import Plan2 from "../../assets/img/plan/plan-2.svg";
import Plan3 from "../../assets/img/plan/plan-3.svg";
import Plan4 from "../../assets/img/plan/plan-4.svg";
import Plan5 from "../../assets/img/plan/plan-5.svg";
import Plan6 from "../../assets/img/plan/plan-6.svg";
import indiaIcon1 from "../../assets/img/plan/india-icon-1.svg";
import indiaIcon2 from "../../assets/img/plan/india-icon-2.svg";
import indiaIcon3 from "../../assets/img/plan/india-icon-3.svg";

import SipCalculationImg from "../../assets/img/plan/sip-calc-image.svg";
import SIPCalculator from "../../components/SIPCalculator/SIPCalculator";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reset, toggleTableView } from "../../slicer/sipSlicer";
import VerticalTabsExample from "../../UI/Tab/Tab";

export default function Plan() {
  // useDispatch()
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(reset());
  }, []);
  const sipBtnHandler = () => {
    dispatch(toggleTableView(true));
    navigate("/sip-calculation");
  };
  const dispatch = useDispatch();
  return (
    <>
      {/* <!-- head-container --> */}
      <div className="top-head-container">
        <div className="container top-head-sub-container">
          <h2 className="head">Plans & Benefits</h2>
        </div>
        <div className="page-info">
          <NavLink to="/">Home</NavLink> / Plans & Benefits
        </div>
      </div>

      {/* <!-- PLAN CONTIANER --> */}
      <div className="plan-container red-light-bg py-5 text-center">
        <div className="container">
          <h6 className="red-head text--2xl">Plans & Benefits</h6>
          <h6 className="blue-head text--6xl fw-bold">A Plan For Every Goal</h6>
          <div
            className="d-flex gap-2 gap-md-4 justify-content-center mx-auto flex-wrap px-md-4 p-4 plan-card__container"
            style={{ maxWidth: "1200px" }}
          >
            <div className="plan-card card">
              <img
                src={Plan1}
                className="img-fluid plan-card__img"
                alt="Plan For Dream Home"
              />
              <p className="plan-card__content">Plan For Dream Home</p>
            </div>
            <div className="plan-card card">
              <img
                src={Plan2}
                className="img-fluid plan-card__img"
                alt="Retirement Planning"
              />
              <p className="plan-card__content">Retirement Planning</p>
            </div>
            <div className="plan-card card">
              <img
                src={Plan3}
                className="img-fluid plan-card__img"
                alt="Plan For Dream Home"
              />
              <p className="plan-card__content">Plan For Dream Home</p>
            </div>
            <div className="plan-card card">
              <img
                src={Plan4}
                className="img-fluid plan-card__img"
                alt="Plan My Wedding"
              />
              <p className="plan-card__content">Plan My Wedding</p>
            </div>
            <div className="plan-card card">
              <img
                src={Plan5}
                className="img-fluid plan-card__img"
                alt="Plan a Vacation"
              />
              <p className="plan-card__content">Plan a Vacation</p>
            </div>
            <div className="plan-card card">
              <img
                src={Plan6}
                className="img-fluid plan-card__img"
                alt="Regular Investor"
              />
              <p className="plan-card__content">Regular Investor</p>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SIP CALCULATOR CONTAINER --> */}
      <section className="sip-calculator-container mx-auto">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 sip-calculator-head">
              <h6 className="fw-bold blue-head sip-calcualte-head text--4xl">
                Calculate the future value of <br />
                your
                <span className="red-head"> SIP Investment </span>
              </h6>
              <p className="blue-head mt-2">
                The Falcon mutual fund SIP calculate the expected <br />
                amount you will accumulate on your monthly investment.
              </p>
              <img
                src={SipCalculationImg}
                alt="sip plan img"
                className="img-fluid w-75 d-none d-lg-block"
              />
            </div>
            <div className="col-lg-6 text-center">
              <div className="sip-calculator mt-4 mt-lg-0">
                <SIPCalculator clickHandler={sipBtnHandler} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- NEXT INVESTMENT --> */}
      <section className="investment-tabination my-4 py-md-4">
        <h6 className="blue-head text--4xl my-2 mb-5 text-center fw-bold">
          Get ideas for your <span className="red-head"> Next Investment</span>
        </h6>
        <div className="container">
          <div className="d-none d-md-block">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <p className="text-start py-0 nav-head">
                          India’s leaders
                        </p>
                        <p className="nav-content">
                          Markets can fluctuate, your peace of mind 
                          shouldn’t
                        </p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <p className="text-start py-0 nav-head">
                          All Weather Investing
                        </p>
                        <p className="nav-content">
                          Markets can fluctuate, your peace of mind 
                          shouldn’t
                        </p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <p className="text-start py-0 nav-head">Smart Save</p>
                        <p className="nav-content">
                          Markets can fluctuate, your peace of mind 
                          shouldn’t
                        </p>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div>
                        <p className="fs-5 fw-bold">India’s leaders</p>
                        <p>
                          A Falcon Asset Management Unit is a firm which ensures
                          the asset Management of an individual investors or an
                          institution or both. Our Management Unit manages the
                          portfolio of an individual ensuring them to enlighten
                          in different phase of life through our SIP plan.
                        </p>
                        <p>
                          Our Management Unit manages the portfolio of an
                          individual ensuring them to enlighten in different
                          phase of life through our SIP plan.
                        </p>
                        <div className="d-flex align-items-center justify-content-around px-3">
                          <div className="text-center">
                            <img
                              src={indiaIcon1}
                              alt="Stability"
                              className="hvr-bob"
                            />
                            <p className="blue-head fw-bold">Stability</p>
                          </div>
                          <div className="text-center">
                            <img
                              src={indiaIcon2}
                              alt="Potential
                                Wealth Creation"
                              className="hvr-bob"
                            />
                            <p className="blue-head fw-bold">
                              Potential <br />
                              Wealth Creation
                            </p>
                          </div>
                          <div className="text-center">
                            <img
                              src={indiaIcon3}
                              alt="5 Years +"
                              className="hvr-bob"
                            />
                            <p className="blue-head fw-bold">5 Years +</p>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div>
                        <p className="fw-bold fw-bold">All Weather Investing (AWI)</p>
                        All Weather Investing (AWI) is a Smart Investment Portfolio (SIP) designed to perform well across different market conditions. It diversifies investments across asset classes like equity, debt, and gold to reduce risk and enhance returns. Falcon offers a hassle-free SIP plan for AWI, allowing investors to automate their investments regularly. 
                        This strategy suits long-term investors seeking stability and growth. AWI balances the portfolio to manage volatility, making it a reliable option for consistent returns. Ideal for those wanting a hands-off, diversified approach to investing through SIPs.
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div>
                        <p className="fs-5 fw-bold">India’s leaders</p>
                        <p>Smart Save is a goal-based investment plan designed to help investors achieve specific financial goals with minimal effort. It optimizes asset allocation across different classes, such as equity, debt, and gold, to ensure balanced growth and protection from market volatility. Falcon’s Smart Save SIP automates regular investments and adjusts the portfolio as needed to stay aligned with the investor's goals. This strategy is ideal for investors looking for a smart, hands-off approach to saving for future milestones, like buying a home or planning for retirement, while maximizing returns and minimizing risks.</p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>

          {/* <!-- MOBILE VIEW --> */}
          <div className="accordion mt-4 d-block d-md-none">
            <Accordion activeIndex={0}>
              <AccordionTab header="India’s leaders">
                <div>
                  <p className="fs-5 fw-bold">India’s leaders</p>
                  <p>
                    A Falcon Asset Management Unit is a firm which ensures the
                    asset Management of an individual investors or an
                    institution or both. Our Management Unit manages the
                    portfolio of an individual ensuring them to enlighten in
                    different phase of life through our SIP plan.
                  </p>
                  <p>
                    Our Management Unit manages the portfolio of an individual
                    ensuring them to enlighten in different phase of life
                    through our SIP plan.
                  </p>
                  <div className="d-flex align-items-center justify-content-around px-3">
                    <div className="text-center">
                      <img
                        src={indiaIcon1}
                        alt="Stability"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">Stability</p>
                    </div>
                    <div className="text-center">
                      <img
                        src={indiaIcon2}
                        alt="Potential
                                Wealth Creation"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">
                        Potential <br />
                        Wealth Creation
                      </p>
                    </div>
                    <div className="text-center">
                      <img
                        src={indiaIcon3}
                        alt="5 Years +"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">5 Years +</p>
                    </div>
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="All Weather Investing">
                <div>
                  <p className="fs-5 fw-bold">India’s leaders</p>
                  <p>
                    A Falcon Asset Management Unit is a firm which ensures the
                    asset Management of an individual investors or an
                    institution or both. Our Management Unit manages the
                    portfolio of an individual ensuring them to enlighten in
                    different phase of life through our SIP plan.
                  </p>
                  <p>
                    Our Management Unit manages the portfolio of an individual
                    ensuring them to enlighten in different phase of life
                    through our SIP plan.
                  </p>
                  <div className="d-flex align-items-center justify-content-around px-3">
                    <div className="text-center">
                      <img
                        src={indiaIcon1}
                        alt="Stability"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">Stability</p>
                    </div>
                    <div className="text-center">
                      <img
                        src={indiaIcon2}
                        alt="Potential
                                Wealth Creation"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">
                        Potential <br />
                        Wealth Creation
                      </p>
                    </div>
                    <div className="text-center">
                      <img
                        src={indiaIcon3}
                        alt="5 Years +"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">5 Years +</p>
                    </div>
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="Smart Save">
                <div>
                  <p className="fs-5 fw-bold">India’s leaders</p>
                  <p>
                    A Falcon Asset Management Unit is a firm which ensures the
                    asset Management of an individual investors or an
                    institution or both. Our Management Unit manages the
                    portfolio of an individual ensuring them to enlighten in
                    different phase of life through our SIP plan.
                  </p>
                  <p>
                    Our Management Unit manages the portfolio of an individual
                    ensuring them to enlighten in different phase of life
                    through our SIP plan.
                  </p>
                  <div className="d-flex align-items-center justify-content-around px-3">
                    <div className="text-center">
                      <img
                        src={indiaIcon1}
                        alt="Stability"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">Stability</p>
                    </div>
                    <div className="text-center">
                      <img
                        src={indiaIcon2}
                        alt="Potential
                                Wealth Creation"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">
                        Potential <br />
                        Wealth Creation
                      </p>
                    </div>
                    <div className="text-center">
                      <img
                        src={indiaIcon3}
                        alt="5 Years +"
                        className="hvr-bob"
                      />
                      <p className="blue-head fw-bold">5 Years +</p>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
          
        </div>
      </section>
    </>
  );
}
