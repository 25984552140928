import { useEffect, useState } from "react";
import { getCall } from "../../services/APICall";
import { Button } from "primereact/button";
import ToastComponent from "../../UI/Toast/Toast";
import searchIcon from "../../assets/img/user-dashboard/shield-search.svg";
import UserTable from "../../UI/Table/UserTable/UserTable";
import "./Loan.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import SuccessIcon from "../../assets/img/user-dashboard/success.png";
import RejectIcon from "../../assets/img/user-dashboard/failed.png";
import CancelIcon from "../../assets/img/user-dashboard/cancel.png";
import PendingIcon from "../../assets/img/user-dashboard/pending.png";
import { MultiSelect } from 'primereact/multiselect';

export default function Loan() {
  const statusList = [
    { name: 'Pending', code: '3' },
    { name: 'Auto Cancelled', code: '9' },
    { name: 'Approved', code: '7' },
    { name: 'Declined', code: '8' },
    { name: 'Cancelled', code: '6' },
  ];
  const [loanList, setLoanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastData, setToastData] = useState({ show: false });
  const [selectedStatus, setSelectedStatus] = useState(statusList);
  const [filteredList, setFilteredList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // New state to track search input

 

  useEffect(() => {
    getLoanList();
  }, []);

  const getLoanList = async () => {
    setLoading(true);
    const { response, result } = await getCall("loan-list");

    if (!response?.ok) {
      setToastData({
        show: true,
        type: "error",
        title: "Error",
        message: result?.message,
      });
    }

    setLoanList(Array.isArray(result?.data) ? result.data : []);
    setFilteredList(result?.data || []); // Initialize filteredList with all data
    setLoading(false);
  };

  const renderStatusBtn = ({ status }) => {
    const statusConfig = {
      1: { label: "Approved", className: "padge-success" },
      8: { label: "Rejected", className: "padge-reject" },
      3: { label: "Pending", className: "padge-pending" },
      6: { label: "Cancel", className: "padge-cancel" },
    };
    
    return (
      <Button
        label={statusConfig[status]?.label || "Unknown"}
        style={{cursor:"default"}}
        className={`p-1 rounded padge-btn cursor-auto ${statusConfig[status]?.className}`}
      />
    );
  };
  useEffect(() => {
    let filtered = loanList;

    // Filter by status if any status is selected
    if (selectedStatus.length > 0) {
      filtered = filtered.filter((investment) =>
        selectedStatus.some((status) => Number(status.code) === Number(investment.status))
      );
    }

    // Filter by search query (invest code)
    if (searchQuery) {
      filtered = filtered.filter((investment) =>
        investment.inv_code.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredList(filtered);
  }, [selectedStatus, searchQuery, loanList]);

  const tableData = [
    { field: "request_date", header: "Date", className: "text-nowrap" },
    { field: "loan_code", header: "Loan ID" },
    { field: "amount", header: "Loan Amount" },
    { field: "loan_duration", header: "Tenure" },
    { field: renderStatusBtn, header: "Status" },
  ];

  const renderAccordionTab = (list) => (
    <AccordionTab
      key={list.loan_code}
      header={
        <span className="row align-items-center ">
          <span className="col-3 text-nowrap">{list.request_date}</span>
          <span className="col-7">{list.loan_code}</span>
          <span className="col-2 ">
            {list.status === 1 && (
              <img src={SuccessIcon} alt="Success Icon" className="img-fluid" />
            )}
            {list.status === 6 && (
              <img src={CancelIcon} alt="Cancel Icon" className="img-fluid" />
            )}
            {list.status === 3 && (
              <img
                src={PendingIcon}
                alt="Pending Icon"
                className="img-fluid"
              />
            )}
            {list.status === 7 && (
              <img src={RejectIcon} alt="Reject Icon" className="img-fluid" />
            )}
          </span>
        </span>
      }
    >
      <div className="row p-2">
        <div className="col-4 d-flex flex-column">
          <span className="primary-head fw-bold">Loan Amount</span>
          <span className="blue-head">{list.amount}</span>
        </div>
        <div className="col-4 d-flex flex-column">
          <span className="primary-head fw-bold">Tenure</span>
          <span className="blue-head">{list.loan_duration}</span>
        </div>
        <div className="col-4">
          <button className="btn blue__btn">
            PDF <i className="bi bi-download"></i>
          </button>
        </div>
      </div>
    </AccordionTab>
  );

  return (
    <>
      <ToastComponent
        className={toastData.type === "error" ? "error-toast" : "success-toast"}
        show={toastData.show}
        type={toastData.type}
        message={toastData.message}
        title={toastData.title}
      />

      <div className="user-info-head d-flex flex-column flex-lg-row justify-content-lg-between">
        <div className="user-info-head__content-container text-start w-100">
          <div className="head">Loan</div>
          <div className="content">
            A loan against investment allows individuals to borrow funds using
            their investments as collateral.
          </div>
        </div>
        <div className="input-container w-100 order-3 order-lg-2 ms-auto me-lg-5 my-3 d-flex justify-content-between justify-content-lg-end gap-2">
          <div className="dropdown status-select form-control">
          <MultiSelect 
              value={selectedStatus} 
              onChange={(e) => setSelectedStatus(e.value)} 
              options={statusList} 
              optionLabel="name"
              placeholder="Select Status"
              maxSelectedLabels={5} 
              className="w-full " 
              style={{ width: "200px" }} 
            />
          </div>
        </div>
      </div>

      {filteredList.length > 0 ? (
        <div className="payment-list-contianer">
          <div className="dashboard-view">
            <UserTable
              loading={loading}
              paginator={false}
              columns={tableData}
              data={loanList}
            />
          </div>

          <div className="mobile-view">
            <div className="row accordion-list-head w-100 mx-auto">
              <div className="col-3">Date</div>
              <div className="col-7">Loan ID</div>
              <div className="col-2">Status</div>
            </div>
            <Accordion activeIndex={0}>
              {filteredList.map(renderAccordionTab)}
            </Accordion>
          </div>
        </div>
      ) : (
        <div className="fw-bold text-secondary fs-4 text-center my-2">
          No Loan data
        </div>
      )}
    </>
  );
}
