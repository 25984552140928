import { configureStore } from "@reduxjs/toolkit";
import {sipReducer} from "../slicer/sipSlicer"
import { dummyUserReducer } from "../slicer/dummyUserSlicer";
import { investReducer } from "../slicer/investSlicer";

export const store = configureStore({
    reducer:{
        sip:sipReducer,
        dummyUser:dummyUserReducer,
        invest:investReducer

    }
})