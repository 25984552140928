import { useEffect, useState } from "react";
import { Slider } from "primereact/slider";
import "./SIPCalculator.css";
import { monthlyInvest, oneTimeInvest } from "../../utils/SIPCalculation";
import DoughnutChart from "../../UI/Chart/ChartPie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInitialData } from "../../slicer/sipSlicer";
import NumberFormat from "react-number-format";
import { formatToINR } from "../../utils/optimize";

// const initialData = {
//   type: "monthly",
//   amount: 5000,
//   rate: 5,
//   time: 5,
// };

export default function SIPCalculator({
  clickHandler,
  tableView,
  btnLabel = "Calculate",
}) {
  const dispatch = useDispatch();
  const initialDatas = useSelector((state) => state.sip);
  const navigate = useNavigate();
  const [sipData, setSipData] = useState();
  const [sipCalcultedData, setSipCalcultedData] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    setSipData(initialDatas?.initialData);
  }, []);

  useEffect(() => {
    const calculateInvestments = () => {
      if (sipData?.type === "onetime") {
        const datas = oneTimeInvest(sipData.amount, sipData.rate, sipData.time);
        setData(datas);
        setSipCalcultedData(data?.result);
      } else {
        const datas = monthlyInvest(sipData.amount, sipData.rate, sipData.time);
        setData(datas);
        setSipCalcultedData(data?.result);
      }
    };

    const debounceTimeout = setTimeout(calculateInvestments, 300);
    return () => clearTimeout(debounceTimeout);
  }, [sipData]);

  useEffect(() => {
    if (data) {
      dispatch(
        updateInitialData({
          ...initialDatas,
          tableView: false,
          initialData: sipData,
          result: data?.result,
          report: data?.list,
        })
      );
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = Number(value);

    // Define minimum and maximum values for each field
    const minValues = { amount: 500, rate: 1, time: 1 };
    const maxValues = { amount: 1000000, rate: 100, time: 25 };
    if (name == "amount" || name == "rate" || name === "time") {
      if (numericValue < minValues[name]) {
        e.target.value = minValues[name];
        setSipData({ ...sipData, [name]: minValues[name] });
      } else if (numericValue > maxValues[name]) {
        e.target.value = maxValues[name];
        setSipData({ ...sipData, [name]: maxValues[name] });
      } else {
        setSipData({ ...sipData, [name]: numericValue });
      }
    } else {
      setSipData({ ...sipData, [name]: value });
    }
  };

  return (
    <div className="calucator-container p-3 py-5">
      <h6 className="text-white fs-2 text-center">SIP Calculator</h6>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between gap-2 sip-top-flex">
          <div className="flex-grow-1 w-100 sip-top-flex__circle ">
            <DoughnutChart datas={data?.result}  />

          </div>
          <div className="result-container d-flex  sip-top-flex__rate">
            <div>
              <p className="mb-1 col-md-12 col-6 text-nowrap">
                Invested amount
              </p>
              <p className="investedAmount col-md-12 col-6">
                {formatToINR(data?.result?.initial)}
              </p>
            </div>
            <div>
              <p className="mb-1 col-md-12 col-6">Est. Returns</p>
              <p className="returnAmount col-md-12 col-6">
                {formatToINR(data?.result?.return)}
              </p>
            </div>
            <div>
              <p className="text-nowrap">Your Future Value</p>
              <p className="futureValue">
                {formatToINR(data?.result?.futureValue)}
              </p>
            </div>
          </div>
        </div>

        <div className="amount-type-container">
          <div className="d-flex justify-content-start justify-md-content-center gap-4 flex-md-row amount-type-container-flex">
            <div>
              <span className="amount-type-container__marker clicked"></span>
              <span className="amount-type-container__content">
                Invested amount
              </span>
            </div>
            <div>
              <span className="amount-type-container__marker"></span>
              <span className="amount-type-container__content">
                Est. Returns
              </span>
            </div>
          </div>
        </div>
        <div className="invest_type_container d-flex gap-3 my-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="type"
              value="monthly"
              onChange={handleChange}
              checked={sipData?.type === "monthly"}
            />
            <label className="form-check-label">Monthly</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="type"
              value="onetime"
              onChange={handleChange}
              checked={sipData?.type === "onetime"}
            />
            <label className="form-check-label">At Once (Lumpsum)</label>
          </div>
        </div>
        <div className="rangers-container my-2">
          <div className="ranger-container">
            <div className="d-flex justify-content-between">
              <div className="label">Monthly Investment*</div>
              <div className="amount">
                &#8377;
                <input
                  className="monthlyInvestValue ms-1"
                  type="number"
                  name="amount"
                  value={sipData?.amount}
                  onChange={handleChange}
                  max={1000000}
                  min={500}
                />
              </div>
            </div>
            <Slider
              value={sipData?.amount}
              min={500}
              max={1000000}
              onChange={(e) => setSipData({ ...sipData, amount: e.value })}
            />
          </div>
        </div>
        <div className="rangers-container my-2">
          <div className="ranger-container">
            <div className="d-flex justify-content-between">
              <div className="label">Expected return rate (p.a)*</div>
              <div className="amount">
                {/* max={100} */}
                <input
                  type="number"
                  className="rateValue text-end pe-1"
                  name="rate"
                  value={sipData?.rate}
                  onChange={handleChange}
                  min={1}
                  max={12}
                />
                %
              </div>
            </div>
            {/* max={100} */}
            <Slider
              value={sipData?.rate}
              max={12}

              min={1}
              onChange={(e) => setSipData({ ...sipData, rate: e.value })}
            />
          </div>
        </div>
        <div className="rangers-container my-2">
          <div className="ranger-container">
            <div className="d-flex justify-content-between">
              <div className="label">Time Period*</div>
              <div className="amount">
                <input
                  type="number"
                  className="timeValue text-end pe-1"
                  name="time"
                  value={sipData?.time}
                  onChange={handleChange}
                  max={25}
                  min={1}
                />
                Years
              </div>
            </div>
            <Slider
              value={sipData?.time}
              min={1}
              max={25}
              onChange={(e) => setSipData({ ...sipData, time: e.value })}
            />
          </div>
        </div>
        <div className="result-container py-3">
          <div className="row align-items-center justify-content-end">
            {/* <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 row">
                  <p className="mb-1 col-md-12 col-6 text-nowrap">
                    Invested amount
                  </p>
                  <p className="investedAmount col-md-12 col-6">
                    {formatToINR(data?.result?.initial)}
                  </p>
                </div>
                <div className="col-md-6 row">
                  <p className="mb-1 col-md-12 col-6">Est. Returns</p>
                  <p className="returnAmount col-md-12 col-6">
                    {formatToINR(data?.result?.return)}
                  </p>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="text-nowrap">Your Future Value :</p>
                  </div>
                  <div className="col-6">
                    <p className="futureValue">
                      {formatToINR(data?.result?.futureValue)}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-4 text-end">
              <button
                className="btn primary__btn shutter_btn_cal hvr-shutter-out-vertical"
                type="button"
                onClick={clickHandler}
              >
                {btnLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
