export function convertDateFormat(dateStr) {
    // Check if dateStr is valid and not empty
    if (!dateStr) return null;
    
    // Extract the date part from the input string
    let datePart = dateStr.split(' ')[0];
  
    // Split the date part by "/"
    let parts = datePart.split('-');
  
    // Ensure the date parts are correctly split
    if (parts.length !== 3) return null;
  
    // Rearrange the parts to form yyyy-mm-dd
    let formattedDate = `${parts[0]}/${parts[1]}/${parts[2]}`;
  
    return formattedDate;
}

export function addDaysToDate(dateString, daysToAdd) {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    date.setDate(date.getDate() + daysToAdd);
    const newDay = ("0" + date.getDate()).slice(-2);
    const newMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const newYear = date.getFullYear();
    return `${newDay}/${newMonth}/${newYear}`;
}

  