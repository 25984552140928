import Slider from "react-slick";
import "./PlanCardCarousel.css";

import DremHomeImg from "../../../assets/img/home/dream-home-icon.svg";
import HomeImg from "../../../assets/img/home/home.png";
import PlanIcon from "../../../assets/img/home/retirement-plan-icon.svg";
import PlanImg from "../../../assets/img/home/plan.png";
import ChildIcon from "../../../assets/img/home/child-plan-icon.svg";
import ChildImg from "../../../assets/img/home/education.png";
import RegularIcon from "../../../assets/img/home/regualar-investor-icon.svg";
import RegularImg from "../../../assets/img/home/investor.png";
import { CustomLeftArrow, CustomRightArrow } from "./CustomArrow"; // Adjust the import path

import RightArrow from "../../../assets/img/home/right-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { planList } from "../../../data/sipInvestPlanData";
import { useNavigate } from "react-router-dom";
import { updateInitialData } from "../../../slicer/sipSlicer";
import { monthlyInvest } from "../../../utils/SIPCalculation";

export default function PlanCardCarousel() {
  const dispatch =useDispatch();
  const navigate = useNavigate()
  
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop sliding
    speed: 1000, // Animation speed
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    // autoplay: true, // Enable auto slide
    autoplaySpeed: 4000,
    arrows: true, // Show arrows
    nextArrow: <CustomRightArrow />, // Custom Next Arrow
    prevArrow: <CustomLeftArrow />, // Custom Prev Arrow
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 595,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const list = [
    {
      head: "Plan For Dream Home",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: DremHomeImg,
      img: HomeImg,
      link: "./plan.html",
      data:planList.home
      
    },
    {
      head: "Retirement Planning",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: PlanIcon,
      img: PlanImg,
      link: "./plan.html",
      data:planList.retirement

    },
    {
      head: "Child Education",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: ChildIcon,
      img: ChildImg,
      link: "./plan.html",
      data:planList.children
    },
    {
      head: "Regular Investor",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: RegularIcon,
      img: RegularImg,
      link: "./plan.html",
      data:planList.regular
    },
    {
      head: "Plan For Dream Home",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: DremHomeImg,
      img: HomeImg,
      link: "./plan.html",
      data:planList.home

    },
    {
      head: "Retirement Planning",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: PlanIcon,
      img: PlanImg,
      link: "./plan.html",
      data:planList.retirement

    },
    {
      head: "Child Education",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: ChildIcon,
      img: ChildImg,
      link: "./plan.html",
      data:planList.children

    },
    {
      head: "Regular Investor",
      content: "A Falcon Asset Management Unit is a firm which ensures...",
      icon: RegularIcon,
      img: RegularImg,
      link: "./plan.html",
      data:planList.regular

    },
    
  ];
  return (
    <>
     
      <div style={{ margin: "0 auto", width: "100%" }}>
        <Slider {...settings}>
          {list.map((item) => (
            <div key={item.head} className="item inverst-card card">
              <div className="card-head p-3">
                <div className="inverst-card__headline">{item.head}</div>
                <div className="invest-card__content">{item.content}</div>
              </div>
              <div className="card-icon">
                <img src={item.icon} alt={item.head} />
              </div>
              <div className="card-body p-0">
                <img
                  src={item.img}
                  alt={item.head}
                  className="img-fluid w-100"
                />
              </div>
              <div className="card-footer p-0 cursor-pointer">
                <a
                  onClick={()=>{
                    const result =monthlyInvest(item?.data.amount,item?.data.rate,item?.data?.time)
                    dispatch(updateInitialData({
                      tableView:false,
                      initialData:item.data,
                      result:result?.result,
                      report:result?.list                      
                    }))
                    
                    navigate('/sip-calculation')
                  }}
                  className="w-100 p-2 view-more__btn hvr-icon-wobble-horizontal"
                >
                  <span>View More</span>
                  <img
                    src={RightArrow}
                    alt="right arrow"
                    className="hvr-icon"
                  />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
