import "./UserTable.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";

export default function UserTable({
  loading,
  data,
  columns,
  first = 1,
  totalRecords = 10,
  rows = 5,
  paginator=true,
  onPageChange,
}) {
  return (
    <>
      <div className="table-container">
        <DataTable value={data} lazy paginator={false} loading={loading}>
          {columns?.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              className={col.className}
            />
          ))}
        </DataTable>
        {paginator&&<Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />}
      </div>
    </>
  );
}
