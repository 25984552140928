import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatToINR } from "../../utils/optimize";

// Register the necessary components from Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = ({ datas }) => {
  // Data for the Doughnut chart
  const data = {
    labels: ["Invest Amount", "Est. Returns"],
    datasets: [
      {
        label: "₹",
        data: [Number(datas?.initial), datas?.return],
        backgroundColor: [
          "#f58220",
          "#fff"
        ],
        borderColor: [
          "#f58220",
          "#fff"
        ],
        borderWidth: 1,
      },
    ],
  };

  // Options for the Doughnut chart
  const options = {
    responsive: true,
    position:'center',
    plugins: {
      legend: {
        display:false,
         
        position: "bottom",
        labels:{
            color:'#fff',
            padding: 20,
          boxWidth: 20,
          boxRadius:5,
          boxHeight: 10,
          font:{
            size:12
          }
            
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        align: 'start',
        anchor: 'start',
        color: '#fff',
        font: {
          size: 18,
          weight: 'bold',
        },
        padding: {
          top: `${datas?.futureValue >9999999999?200:120}`, // Adjust padding for better centering if needed
          right:``
        },
        formatter: (value, context) => {
          // Display the total value in the center of the chart
          if (context.dataIndex === 0) {
            return `${formatToINR(datas?.futureValue)}`; // Display the total value
          }
          return '';
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 2, // Adjust border width here
      },
    },
    cutout: '88%', // Set the cutout size for thickness
    rotation: -0.5 * Math.PI, // Rotate the chart to start from a different angle
  };

  return (
    <div style={{ width:`${datas?.futureValue >9999999999? "300px":"200px"}` }} className="mx-auto my-4">
      <Doughnut data={data} options={options}  className="mx-auto w-100 h-auto"/>
    </div>
  );
};

export default DoughnutChart;
