import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavbar from "../components/SideNavbar/SideNavbar";
import { useEffect, useState } from "react";
import { getAuthToken } from "../utils/auth";
import ReusaableModal from "../UI/Modal/Modal";
import SIPCalculator from "../components/SIPCalculator/SIPCalculator";
import { useDispatch, useSelector } from "react-redux";
import { toggleInvestView, toggleSipCalculateView } from "../slicer/investSlicer";

export default function UserLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [visible,setVisible] = useState()
  const investModalData =useSelector((state)=>state.invest);
  useEffect(() => {
    const token = getAuthToken();
    if (!token) {
      navigate("/auth/login");
    }
  }, []);
  useEffect(()=>{
    setVisible(investModalData.sipModal)


  },[investModalData]);
  const calculatorClickHandler =()=>{
    dispatch(toggleSipCalculateView(false));
    setTimeout(() => {
      dispatch(toggleInvestView(true));
      navigate('/user/dashboard')
      
    }, 200);
  }
  return (
    <>
      <div className="red-light-bg" >
        {/* <p className="bg-blue p-1 text-white info-scroll-content">
          <marquee>
            Whether you are saving for a dream vacation, a dream house,
            retirement or your childs future, there is a fund designed to help
            you achieve your unique goals.
            <button className="btn bg-transparent primary-head" onClick={()=>dispatch(toggleSipCalculateView(true))}>
              Invest Now
            </button>
          </marquee>
        </p> */}
        {pathname=="/user/dashboard"&&<div className="text-center py-3">
         <button className="btn invest-now__btn" id="blinking-button" onClick={()=>dispatch(toggleSipCalculateView(true))}>
              Invest Now
            </button>

        </div>}
        <div className="d-flex gap-2 justify-content-center  py-2 px-4 mx-auto" style={{ minHeight: "80vh" ,maxWidth:"1600px"}}>
          <div className={`${window.outerWidth>801?'d-block':'d-none'} `} >
            <SideNavbar />
          </div>
          {/* <div  className={`${window.outerWidth>801?'col-md-9 col-xl-9':'col-12'} `}> */}
          <div  className="flex-grow-1">
            <Outlet />
          </div>
        </div>
      </div>
      <ReusaableModal show={visible} style={{width:"700px"}} closeButton={false} handleClose={()=>{dispatch(toggleSipCalculateView(false));setVisible(false)}} >
        <section className="sip-calculator-container mx-auto">
          <div className="sip-calculator mt-4 mt-lg-0">
            <SIPCalculator btnLabel="Invest Now" clickHandler={calculatorClickHandler}/>
          </div>
        </section>
      </ReusaableModal>
    </>
  );
}
