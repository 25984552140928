const optimizeList =(list, idFieldName,stateFieldName )=>{
    const listOptimized = list.map((item)=>{
        return {
            value:item[idFieldName],
            label:item[stateFieldName]
        }
    });
    return listOptimized
}

    const formatToINR = (number, includeSymbol=true) => {
      const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
      });
  
      const formattedNumber = formatter.format(number);
      
      // Remove the currency symbol if not required
      if (!includeSymbol) {
        return formattedNumber.replace('₹', '');
      }
  
      return formattedNumber;
    };

module.exports={
    optimizeList,
    formatToINR
}