import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  investModal:false,
  sipModal:false
};

const investSlice = createSlice({
  name: "invest",
  initialState,
  reducers: {
    toggleInvestView(state, action) {
      state.investModal = action.payload;
    },
    toggleSipCalculateView(state, action) {
        state.sipModal = action.payload;
    },
    
    reset(state){
      return initialState
    }
  },
});

export const { toggleInvestView, toggleSipCalculateView,reset } = investSlice.actions;
export const investReducer = investSlice.reducer;
