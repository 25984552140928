import Slider from "react-slick";

import './LogoCarousel.css';
import Logo1 from '../../../assets/img/aboutus/about-img-1.svg'
import Logo2 from '../../../assets/img/aboutus/about-img-2.svg'
import Logo3 from '../../../assets/img/aboutus/about-img-3.svg'
import Logo4 from '../../../assets/img/aboutus/about-img-4.svg'
import Logo5 from '../../../assets/img/aboutus/about-img-5.svg'
import { CustomLeftArrow, CustomRightArrow } from "../PlanCardCarousel/CustomArrow";

export default function LogoCarousel(){
    const settings = {
        dots: true, // Show navigation dots
        infinite: true, // Infinite loop sliding
        speed: 1000, // Animation speed
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: true, // Enable auto slide
        autoplaySpeed: 4000,
        arrows: true, // Show arrows
        nextArrow: <CustomRightArrow />, // Custom Next Arrow
        prevArrow: <CustomLeftArrow />, // Custom Prev Arrow
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      };
    
      const logoList = [
        {
          id: 1,
          img: Logo1,
          altContent: "FedEx",
        },
    
        {
          id: 2,
          img: Logo2,
          altContent: "Intel",
        },
        {
          id: 3,
          img: Logo3,
          altContent: "Sunrise",
        },
        {
          id: 4,
          img: Logo4,
          altContent: "ECR",
        },
        {
          id: 5,
          img: Logo5,
          altContent: "ZARA",
        },
        {
          id: 1,
          img: Logo1,
          altContent: "FedEx",
        },
    
        {
          id: 2,
          img: Logo2,
          altContent: "Intel",
        },
        {
          id: 3,
          img: Logo3,
          altContent: "Sunrise",
        },
        {
          id: 4,
          img: Logo4,
          altContent: "ECR",
        },
        {
          id: 5,
          img: Logo5,
          altContent: "ZARA",
        },
      ];
    
      return (
        <div style={{ margin: "0 auto", width: "100%" }}>
          <Slider {...settings}>
            {logoList.map((image) => (
              <div key={image.id}>
                <div style={{ padding: "10px" }}>
                  <img
                    src={image.img}
                    alt={`Slide ${image.alt}`}
                    style={{ }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      );
}