import { Outlet, useNavigate } from "react-router-dom";
import LoginIcon from "../assets/img/user/registration-icon.png";
import { useEffect } from "react";
import { getAuthToken } from "../utils/auth";

export default function AuthLayout(){
  const navigate =useNavigate()
  useEffect(()=>{
    const token =getAuthToken();
    if(token){
      navigate('/user/dashboard')
    }
  },[]);
    return<>
    <div className="container-fluid register-container px-0">
      <div className="d-flex">
        <div className="img-container d-md-block d-none">
          <div className="img-inner-container">
            <img src={LoginIcon} alt="Login Icon" />
            <p className="head-content">Get More with My Account</p>
            <p className="body-content">Any Time, Anywhere</p>
          </div>
        </div>
            <Outlet />

        </div>
    </div>
    </>
}