import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";
import { Modal, Button } from "react-bootstrap";
import "./OTPModal.css";
import HeadContent from "../../HeadContent/HeadContent";
import {
  caesarEncrypt,
  splitFirstAndLastLetter,
} from "../../../utils/Encryption";
import ReusabableModal from "../Modal";
import { useNavigate  } from "react-router-dom";

const OTPModal = ({
  show,
  sendInfo,
  handleClose,
  title,
  children = null,
  footer = true,
}) => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(59);
  const [hasStarted, setHasStarted] = useState(false);
  const [timerComplete, setTimerComplete] = useState(false);
  const [error, setError] =useState()
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (show) {
      setTimer(59);
      setHasStarted(true);
      setTimerComplete(false); // Reset timer complete state
    }
  }, [show]);



  useEffect(() => {
    if (timer > 0 && hasStarted && !timerComplete) {
      const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeoutId);
    } else if (timer === 0) {
      setTimerComplete(true);
    }
  }, [timer, hasStarted, timerComplete]);

  const otpResendHandler = async() => {
    if (timerComplete) {
      const payload = {
        username: sendInfo?.email,
      };
  
      const encryptData = caesarEncrypt(JSON.stringify(payload));
      // Optionally reset timer or handle OTP resend logic
      setTimerComplete(false);
      const response = await fetch(`${backendUrl}registration-resend-otp`, {
        method: "POST",
        body: `"${encryptData}"`,
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fhyDNulWAg9NzBsLmw4Lf6J47pEhQI37w5rWVu9uF",
        },
      });
      const result = await response.json()

      setTimer(59);
      setTimerComplete(true);


    }
  };

  const otpSubmitHandler = async () => {
    const payload = {
      username: sendInfo?.email,
      otp,
    };

    const encryptData = caesarEncrypt(JSON.stringify(payload));
    const response = await fetch(`${backendUrl}registration-verify-otp`, {
      method: "POST",
      body: `"${encryptData}"`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "fhyDNulWAg9NzBsLmw4Lf6J47pEhQI37w5rWVu9uF",
      },
    });
    const result = await response.json();
    if (result.status == "error") {
      //show error notification
      setError(result?.error)
      
    }else{
      setError('');
      navigate('/auth/login');
      handleClose();  // Close the modal
      
    }
  };

  return (
    <>
      <Modal
        show={show && hasStarted}
        onHide={handleClose}
        className="otp-modal"
      >
        <Modal.Header closeButton className="custom-close-btn">
          <Modal.Title>
            <HeadContent>
              <span className="blue-head fs-3">Verify </span>
              <span className="fs-3">OTP</span>
            </HeadContent>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxWidth: "320px" }} className="mx-auto">
          {error !=""&& <span className=" d-block text-center red-head">{error}</span>}
            <div className="user-info">
              <p className="fw-bold">OTP Sent to {sendInfo?.email}</p>
            </div>
            <div className="otp-inputs-container">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span> </span>}
                inputType="number"
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="d-flex mt-3 justify-content-between align-items-center">
              <p>
                Didn’t receive OTP?{" "}
                <button
                  href="#"
                  className="btn py-0 px-1 otp__btn"
                  disabled={timer > 0}
                  onClick={otpResendHandler}
                >
                  <span className="red-head">Resend</span>
                </button>
              </p>
              <p>00:{timer}</p>
            </div>
          </div>
        </Modal.Body>
        {otp.length === 4 && (
          <Modal.Footer className="text-center justify-content-center">
            <button className="btn red__btn px-4" onClick={otpSubmitHandler}>
              Submit
            </button>
          </Modal.Footer>
        )}
      </Modal>
      {/* <div class="alert alert-primary" role="alert">
  This is a primary alert—check it out!
</div> */}
    </>
  );
};

OTPModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
};

OTPModal.defaultProps = {
  children: null,
  footer: null,
};

export default memo(OTPModal);
