// ToastComponent.js
import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

import ErrorIcon from "../../assets/img/user-dashboard/toast-error.png";
import SuccIcon from "../../assets/img/user-dashboard/toast-succ.png";
import InfoIcon from "../../assets/img/user-dashboard/toast-info.png";
import WarnIcon from "../../assets/img/user-dashboard/toast-warn.png";
import "./Toast.css";

const ToastComponent = ({
  show,
  title,
  message,
  onClose,
  className,
  type = "error",
}) => {
  return (
    <ToastContainer position="top-end" className={`p-3 ${className}`}>
      <Toast show={show} onClose={onClose} delay={5000} autohide>
        <Toast.Body>
          <div className="d-flex gap-3 align-items-center p-1">
            <div>
              {type == "error" && <img src={ErrorIcon} alt="error icon" />}
              {type == "success" && <img src={SuccIcon} alt="Success icon" />}
              {type == "info" && <img src={InfoIcon} alt="Info icon" />}
              {type == "warn" && <img src={WarnIcon} alt="Warn icon" />}
            </div>
            <div>
                <p className="toast-title">{title}</p>
                <p className="toast-content">{message}</p>

            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastComponent;
