const planList = {
  home: {
    type: "monthly",
    amount: 8000,
    rate: 14,
    time: 12,
  },
  retirement:{
    type: "monthly",
    amount: 5000,
    rate: 14,
    time: 15,

  },
  children:{
    type: "monthly",
    amount: 6000,
    rate: 15,
    time: 14,

  },
  regular:{
    type: "monthly",
    amount: 5000,
    rate: 5,
    time: 5,

  }
  
};

module.exports ={
    planList
}